import {
  Card,
  FormControlLabel,
  FormHelperText,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import log from 'loglevel';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuestion } from 'graphql/hooks/useQuestion';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDEditor from 'components/MDB/MDEditor';
import MDTypography from 'components/MDB/MDTypography';
import Answers from 'components/Question/Answers';
import Metadata from 'components/Question/Metadata';

import { useGlobalState, useQuestionState, useSearchState } from 'stores';

import {
  highlightMarkWord,
  highlightUnmarkWord,
  prettyDate,
  scrollToTop,
} from 'helpers';

import ActionDialog from '../ActionDialog';
import './transitions.css';

interface Props {
  highlights?: string[];
  onSave: any;
}

const QuestionForm: FC<Props> = ({ highlights, onSave }) => {
  const [deleteActive, setDeleteActive] = useState(false);
  const [duplicateActive, setDuplicateActive] = useState(false);

  const {
    question,
    questionAddEmptyAnswer,
    questionErrors,
    questionHasType,
    // questionIsValid,
    questionOptions,
    questionModified,
    questionRestore,
    questionUpdateField,
    questionIsInvalid,
  } = useQuestionState();
  const { searchHighlight, searchHighlightToggle } = useSearchState();
  const { globalLoadingSet } = useGlobalState();

  const { duplicateQuestion, deleteQuestion } = useQuestion();
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      <Grid item sx={{ width: '275px' }}>
        <Metadata />
      </Grid>
      <Grid item xs>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                sx={{
                  borderLeft: 2,
                  borderColor: `${questionOptions.colorName}.main` ?? '',
                }}
              >
                <MDBox p={2}>
                  {questionHasType ? (
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          mb={1}
                        >
                          <Grid item>
                            <MDTypography variant="body1">
                              Question:
                            </MDTypography>
                          </Grid>

                          {highlights && highlights.length > 0 && (
                            <Grid item>
                              <FormControlLabel
                                control={<Switch checked={searchHighlight} />}
                                onChange={() => searchHighlightToggle()}
                                color="secondary"
                                label="Highlight Search Text"
                              />
                            </Grid>
                          )}
                        </Grid>
                        <MDEditor
                          value={
                            searchHighlight
                              ? highlightMarkWord(question.text, highlights)
                              : question.text || ''
                          }
                          hasImage={questionOptions.hasImages}
                          readOnly={!questionHasType}
                          onChange={(newValue) => {
                            questionUpdateField({
                              field: 'text',
                              value: highlightUnmarkWord(newValue),
                            });
                          }}
                        />
                        <FormHelperText error>
                          {questionErrors &&
                            questionErrors?.text &&
                            questionErrors?.text}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  ) : (
                    <MDBox mb={2}>
                      <Skeleton variant="rectangular" height={100} />
                    </MDBox>
                  )}

                  {questionHasType && <Answers />}

                  {questionHasType && (
                    <Grid
                      container
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Grid item mb={2}>
                        <FormHelperText error>
                          {questionErrors &&
                          questionErrors?.answers &&
                          questionErrors?.answers?.message
                            ? questionErrors.answers.message.toString()
                            : ''}
                        </FormHelperText>
                      </Grid>
                      {!questionOptions.isTrueFalse && (
                        <Grid item>
                          <MDButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => questionAddEmptyAnswer()}
                          >
                            Add Answer
                          </MDButton>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {questionOptions.hasGeneralFeedback && (
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12}>
                        <MDTypography variant="body2">Feedback:</MDTypography>
                        <MDEditor
                          value={
                            searchHighlight
                              ? highlightMarkWord(question.feedback, highlights)
                              : question.feedback || ''
                          }
                          onChange={(newValue) =>
                            questionUpdateField({
                              field: 'feedback',
                              value: highlightUnmarkWord(newValue),
                            })
                          }
                        />
                        <FormHelperText error>
                          {questionErrors?.feedback}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={3} mb={2}>
                    <Grid item xs={12}>
                      <MDTypography variant="body2">
                        <b>Created:</b> {prettyDate(question.created)}
                        <br />
                        <b>Last Modified:</b> {prettyDate(question.modified)}
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      {question._id && (
                        <MDButton
                          variant="text"
                          color="error"
                          onClick={() => setDeleteActive(true)}
                        >
                          Delete
                        </MDButton>
                      )}
                      {question._id && (
                        <MDButton
                          variant="text"
                          color="secondary"
                          disabled={questionModified}
                          onClick={() => setDuplicateActive(true)}
                        >
                          Duplicate
                        </MDButton>
                      )}
                    </Grid>
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        <MDButton
                          variant="text"
                          color="secondary"
                          disabled={!questionModified}
                          onClick={questionRestore}
                        >
                          Undo All Changes
                        </MDButton>

                        <MDButton
                          variant="contained"
                          color="info"
                          disabled={questionIsInvalid || !questionModified}
                          type="submit"
                          onClick={onSave}
                        >
                          Save
                        </MDButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
      <ActionDialog
        onAction={async () => {
          await globalLoadingSet(true, 'Deleting');
          setDeleteActive(false);
          deleteQuestion(question._id)
            .then(() => navigate('/questions/search'))
            .catch((e) => log.error(e))
            .then(() => globalLoadingSet(false));
        }}
        actionLabel="Delete"
        onClose={() => setDeleteActive(false)}
        open={deleteActive}
        message={question.text}
        title="Are you sure you want to delete the following question?"
        severity="error"
      />
      <ActionDialog
        onAction={async () => {
          await globalLoadingSet(true, 'Duplicating');
          setDuplicateActive(false);
          duplicateQuestion(question._id)
            .then((res) => {
              if (res._id) {
                scrollToTop();
                navigate(`/questions/edit/${res._id}`);
              }
            })
            .catch((e) => log.error(e))
            .then(() => globalLoadingSet(false));
        }}
        actionLabel="Duplicate"
        onClose={() => setDuplicateActive(false)}
        open={duplicateActive}
        message={question.text}
        title="Are you sure you want to duplicate this question?"
      />
    </Grid>
  );
};

export default QuestionForm;
