import {
  Alert,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { trim } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { useQuestion } from 'graphql/hooks/useQuestion';

import AutocompleteField from 'components/Forms/Autocomplete';
import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';

import { useNotifications, useQuestionState } from 'stores';

import rgba from 'assets/theme/functions/rgba';

interface SearchBulkEditDialogProps {
  open?: boolean;
  onClose: () => void;
}

// A custom theme for this app
const SearchClone: FC<SearchBulkEditDialogProps> = ({
  open = false,
  onClose,
}) => {
  const [pending, setPending] = useState(false);
  const [sourceType, setSourceType] = useState<string | undefined>(undefined);
  const [sourceSeries, setSourceSeries] = useState<string | undefined>(
    undefined,
  );
  const [targetSeries, setTargetSeries] = useState<string[] | undefined>(
    undefined,
  );
  const [targetError, setTargetError] = useState(true);

  const { cloneSeries } = useQuestion();
  const { questionTypes } = useQuestionState();

  useEffect(() => {
    setSourceType(undefined);
    setSourceSeries(undefined);
    setTargetSeries(undefined);
  }, [open]);

  useEffect(() => {
    setTargetError(
      targetSeries?.length
        ? facets.series
            .map((item) => item._id)
            .some((r) => targetSeries?.includes(r || ''))
        : true,
    );
  }, [targetSeries]);

  const { addNotification } = useNotifications();
  const { getFacets } = useQuestion();
  const facets = getFacets();

  const handleClone = async () => {
    if (sourceSeries && targetSeries) {
      setPending(true);
      cloneSeries(sourceSeries, targetSeries, sourceType)
        .then((ids) => {
          if (ids && ids.length > 0) {
            addNotification({
              severity: 'success',
              message: `Successfully cloned ${ids.length} questions into ${targetSeries}`,
            });
            onClose();
          } else {
            addNotification({
              severity: 'error',
              message: `Successfully cloned ${ids?.length} questions into ${targetSeries}`,
            });
          }
        })
        .catch((e) =>
          addNotification({
            severity: 'error',
            message: e.message,
          }),
        )
        .then(() => setPending(false));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <div style={{ position: 'relative' }}>
        <DialogTitle>Clone Series</DialogTitle>
        <DialogContent>
          <MDBox mb={1}>
            <Alert severity="warning">
              Question type is optional. If a question type is not selected, all
              questions in that series across all question types will be cloned.
            </Alert>
            <AutocompleteField
              label="Question Type"
              value={sourceType}
              onSelect={(selected) => {
                setSourceType(selected);
              }}
              getOptionLabel={(option) =>
                questionTypes
                  ? questionTypes[option]
                    ? questionTypes[option].label
                    : option
                  : option
              }
              options={Object.values(questionTypes || {}).map(({ _id }) => _id)}
              disableClearable
            />
          </MDBox>
          <MDBox mb={1}>
            <AutocompleteField
              label="Source Series"
              value={sourceSeries}
              onSelect={(selected) => {
                setSourceSeries(selected);
              }}
              error={(sourceSeries?.length || 0) < 1}
              helpText={!sourceSeries ? 'Source Series cannot be empty' : ''}
              options={facets.series.map((item) => item._id)}
              disableClearable
              required
            />
          </MDBox>
          <MDBox mb={1}>
            <AutocompleteField
              label="Target Series"
              error={targetError}
              required
              helpText={
                targetError
                  ? 'The target series cannot be empty or an existing series.'
                  : ''
              }
              value={targetSeries}
              onSelect={(selected) =>
                setTargetSeries(
                  selected.map((item) => trim(item.toLowerCase())),
                )
              }
              options={[]}
              multiple
              freeSolo
            />
          </MDBox>
          <Backdrop
            sx={{
              background: rgba('#FFFFFF', 0.5),
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: 'absolute',
            }}
            open={pending}
            onClick={() => {
              //
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClose} sx={{ mt: 1, mr: 1 }} disabled={pending}>
            Cancel
          </MDButton>
          <MDButton
            color="info"
            onClick={handleClone}
            sx={{ mt: 1, mr: 1 }}
            disabled={
              pending ||
              sourceSeries?.length === 0 ||
              targetSeries?.length === 0
            }
          >
            Clone
          </MDButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default SearchClone;
