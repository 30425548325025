import Card from '@mui/material/Card';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';

import { useAutocompleteT } from 'translate';

import { useRealmUser } from 'providers/RealmUserProvider';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDInput from 'components/MDB/MDInput';
import MDTypography from 'components/MDB/MDTypography';

import useNotifications from 'stores/notification';

interface Props {
  onResetRequest: () => any;
}

const LoginForm: FC<Props> = ({ onResetRequest }) => {
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const { search } = useLocation();
  const navigate = useNavigate();
  const { emailPasswordLogin } = useRealmUser();
  const { addNotification } = useNotifications();
  const { T } = useAutocompleteT();

  const onSubmit = async () =>
    emailPasswordLogin(form.email, form.password)
      .then(() => {
        const redirectTo = search.replace('?redirectTo=', '');
        navigate(redirectTo ? redirectTo : '/');
      })
      .catch(() =>
        addNotification({
          message: T('login.form.error.message'),
          severity: 'error',
        }),
      );

  const handleFormInputChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  return (
    <Card>
      <MDBox pt={4} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              value={form.email}
              fullWidth
              error={form.email.length > 0 && !isEmail(form.email)}
              helperText={
                form.email.length > 0 && !isEmail(form.email)
                  ? T('validation.email')
                  : ''
              }
              onChange={handleFormInputChange}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Password"
              name="password"
              autoComplete="off"
              value={form.password}
              onChange={handleFormInputChange}
              fullWidth
            />
          </MDBox>
          <MDBox mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={!isEmail(form.email) || form.password.length <= 3}
              onClick={onSubmit}
            >
              sign in
            </MDButton>
          </MDBox>
          <MDBox mt={1} mb={2} textAlign="center">
            <MDTypography variant="button" color="text">
              {T('login.form.text')}
              <MDTypography
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
                onClick={onResetRequest}
              >
                {T('login.form.resetAction')}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default LoginForm;
