import {Card, Grid} from '@mui/material';
import {cloneDeep, isArray, omit} from 'lodash';
import React, {FC, useEffect, useState} from 'react';

import {FacetsFilter} from 'graphql/apollo';
import useFacetsAndTypes from 'graphql/hooks/useFacetsAndTypes';

import AutocompleteField from 'components/Forms/Autocomplete';
import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';

import {useQuestionState} from 'stores';

interface Props {
  label?: string;
  action?: (filters: FacetsFilter) => void;
  remaining?: number;
}

const FacetFilter: FC<Props> = ({ label = 'Submit', action, remaining }) => {
  const { facetsFilteredSubscription } = useFacetsAndTypes();
  const { questionTypes } = useQuestionState();

  const [filters, setFilters] = useState<FacetsFilter>({});
  const { data: facets, loading: facetsLoading } = facetsFilteredSubscription({
    variables: { filters },
  });

  const [options, setOptions] = useState<any>({
    type: [],
    ranks: [],
    series: [],
    procedures: [],
    tags: [],
  });

  const [hasRemaining, setHasRemaining] = useState(false);

  useEffect(() => {
    if (!facetsLoading && !!facets)
      if (facets.questionFacets && !filters.procedures) {
        const newOptions = {};
        const receivedFacets = cloneDeep(facets.questionFacets) || {};
        Object.entries(receivedFacets).forEach(([facet, value]) => {
          if (value && isArray(value)) {
            newOptions[facet] = value
              .filter((facet) => facet?._id)
              .map((facet) => facet?._id);
          } else {
            newOptions[facet] = [];
          }
        });
        setOptions(newOptions);
      }
  }, [facetsLoading, facets]);

  useEffect(() => {
    if (remaining && remaining > 0) {
      setHasRemaining(true);
    } else {
      setHasRemaining(false);
    }
  }, [remaining]);

  const updateFilter = (type: string, value: string) => {
    setFilters((prevState) => {
      if (value.length > 0) {
        return { ...prevState, [type]: value };
      }
      return omit(prevState, type);
    });
  };

  return (
    <Card>
      <MDBox p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <AutocompleteField
              label="Type"
              value={filters.type || ''}
              getOptionLabel={(option) =>
                questionTypes
                  ? questionTypes[option]
                    ? questionTypes[option].label
                    : option
                  : option
              }
              onSelect={(value) => updateFilter('type', value)}
              options={Object.values(questionTypes || {}).map(({ _id }) => _id)}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              label="Rank"
              value={filters.rank || ''}
              disabled={options.ranks.length === 0}
              onSelect={(value) => updateFilter('rank', value)}
              options={options.ranks}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              label="Series"
              value={filters.series || ''}
              disabled={options.series.length === 0}
              onSelect={(selected) => updateFilter('series', selected)}
              options={options.series}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              label="Procedures"
              multiple={true}
              value={filters.procedures || []}
              onSelect={(selected) => updateFilter('procedures', selected)}
              options={options.procedures}
            />
          </Grid>
          <Grid item xs={12}>
            <MDButton
              onClick={action ? () => action(filters) : undefined}
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                facets?.questionFacets
                  ? facets?.questionFacets?.questionCount
                    ? parseInt(facets?.questionFacets?.questionCount, 10) >=
                      1000
                    : false
                  : false
              }
            >
              {remaining ? 'Re' : ''}
              {label}
            </MDButton>
          </Grid>
          {parseInt(facets?.questionFacets?.questionCount || '1000', 10) >
            999 && (
            <Grid item xs={12} mb={2}>
              <MDTypography variant="body2" sx={{ lineHeight: '1.2rem' }}>
                About <strong>{facets?.questionFacets?.questionCount}</strong>{' '}
                questions are selected. In order to start, there must be less
                than 1000 questions.
              </MDTypography>
            </Grid>
          )}
          {hasRemaining && (
            <Grid item xs={12} mb={2}>
              <MDTypography variant="body2">
                Questions Remaining: {remaining}
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
};

export default FacetFilter;
