import { CircularProgress, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AdvancedLayout from 'layouts/AdvancedLayout';

import { QuestionUpdateInput } from 'graphql/apollo';
import { useQuestion } from 'graphql/hooks/useQuestion';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';
import QuestionForm from 'components/Question/Question';

import {
  useGlobalState,
  useNotifications,
  useQuestionState,
  useSearchState,
} from 'stores';

import { assureQuestion, delay } from 'helpers';

const Edit: FC = () => {
  // local state
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [highlights, setHighlights] = useState<undefined | string[]>(undefined);

  // global state
  const { questionUpdate, questionUpdateField, questionSnapshot, question } =
    useQuestionState();

  const { state: locationState } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { globalLoadingSet } = useGlobalState();

  const { addNotification } = useNotifications();
  const { searchHighlight, searchHighlightToggle } = useSearchState();

  const { getQuestion, updateQuestion } = useQuestion();

  const retrieveQuestion = (id) => {
    if (loading) return;
    return getQuestion(id)
      .then((question) => cloneDeep(question))
      .catch(() => undefined);
  };

  const onSaveQuestion = async () => {
    globalLoadingSet(true, 'Saving');
    let hadHighlight = false;
    if (searchHighlight) {
      hadHighlight = true;
      await searchHighlightToggle();
      await delay(500);
    }
    updateQuestion(question._id, question as QuestionUpdateInput)
      .then(() => {
        addNotification({
          message: 'Question successfully saved',
          severity: 'success',
        });
        questionUpdateField({
          field: 'modified',
          value: new Date().toISOString(),
        });
        questionSnapshot();
      })
      .catch(() => {
        addNotification({
          message: 'Question could not be saved, please try again.',
          severity: 'error',
        });
      })
      .then(() => {
        if (hadHighlight) {
          searchHighlightToggle();
        }
        globalLoadingSet(false);
      });
  };

  useEffect(() => {
    let ignore = false;

    (async () => {
      setLoading(true);
      const question = await retrieveQuestion(id);
      if (!ignore && question) {
        await questionUpdate(assureQuestion(question));
        questionSnapshot();
        setLoaded(true);
      }
      setLoading(false);
    })();

    return () => {
      ignore = true;
    };
  }, [id]);

  useEffect(() => {
    if (locationState) {
      setHighlights(locationState.highlights);
    }
  }, [locationState]);

  return (
    <AdvancedLayout>
      {loading && (
        <MDBox ml={2}>
          <CircularProgress />
        </MDBox>
      )}
      {loaded && (
        <QuestionForm highlights={highlights} onSave={() => onSaveQuestion()} />
      )}
      {!loading && !loaded && (
        <Grid container>
          <Grid item xs={12} p={2}>
            <MDTypography>Question Does Not Exist</MDTypography>
            <MDButton onClick={() => navigate('/questions/search')}>
              Search for a Question
            </MDButton>
          </Grid>
        </Grid>
      )}
    </AdvancedLayout>
  );
};

export default Edit;
