import Grid from '@mui/material/Grid';
import React, { FC } from 'react';

import AdvancedLayout from 'layouts/AdvancedLayout';

import MDBox from 'components/MDB/MDBox';

import QuestionInfoCard from '../components/Cards/QuestionInfoCard';
import { useQuestion } from '../graphql/hooks/useQuestion';

const Dashboard: FC = () => {
  const { getFacets } = useQuestion();
  const facets = getFacets();

  // const { sales, tasks } = reportsLineChartData;
  //
  // // Action buttons for the BookingCard
  // const actionButtons = (
  //   <>
  //     <Tooltip title="Refresh" placement="bottom">
  //       <MDTypography
  //         variant="body1"
  //         color="primary"
  //         lineHeight={1}
  //         sx={{ cursor: 'pointer', mx: 3 }}
  //       >
  //         <Icon color="inherit">refresh</Icon>
  //       </MDTypography>
  //     </Tooltip>
  //     <Tooltip title="Edit" placement="bottom">
  //       <MDTypography
  //         variant="body1"
  //         color="info"
  //         lineHeight={1}
  //         sx={{ cursor: 'pointer', mx: 3 }}
  //       >
  //         <Icon color="inherit">edit</Icon>
  //       </MDTypography>
  //     </Tooltip>
  //   </>
  // );

  return (
    <AdvancedLayout>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}></MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {facets &&
              facets.types &&
              facets.types.map(({ _id, count }) => {
                return (
                  <MDBox mb={1} key={_id}>
                    <QuestionInfoCard
                      title={_id}
                      count={count || ''}
                      icon="alert"
                      description={'some description'}
                    />
                  </MDBox>
                );
              })}
          </Grid>
        </Grid>
      </MDBox>
    </AdvancedLayout>
  );
};

export default Dashboard;
