import {Card, Grid, Skeleton} from '@mui/material';
import {Document, Font, PDFViewer, StyleSheet} from '@react-pdf/renderer';
import React, {FC, useState} from 'react';

import AdvancedLayout from 'layouts/AdvancedLayout';

import {SearchFilter} from 'graphql/apollo';
import useQuestion from 'graphql/hooks/useQuestion';

import PageExport from 'components/Exports/Page';
import MDBox from 'components/MDB/MDBox';
import FacetFilter from 'components/Question/FacetFilter';

import {Question} from 'types/questions';

Font.register({
  family: 'arial',
  src: 'https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf',
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Helvetica',
  },
  section: {
    margin: 10,
    padding: 10,
    fontFamily: 'Helvetica',
  },
  viewer: {
    width: '100%', //the pdf viewer will take up all of the width and height
    height: '500px',
    fontFamily: 'Helvetica',
  },
});

const Export: FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);

  const { searchQuestions } = useQuestion();

  const generate = (filters: SearchFilter) => {
    console.log(filters)
    searchQuestions({ ...filters, options:{ any: true }, perPage: 1000 }).then((results) => {
      if (results?.hits) {
        setQuestions(results.hits as unknown as Question[]);
      } else {
        setQuestions([]);
      }
    });
  };

  return (
    <AdvancedLayout>
      <Grid container spacing={2}>
        <Grid item sx={{ width: '275px' }}>
          <FacetFilter label="Generate PDF" action={generate} />
        </Grid>
        <Grid item xs>
          <Card>
            <MDBox p={2}>
              {questions.length > 0 ? (
                <PDFViewer style={styles.viewer}>
                  <Document>
                    {questions.map((question, index) => (
                      <PageExport question={question} key={index} />
                    ))}
                  </Document>
                </PDFViewer>
              ) : (
                <Skeleton variant="rectangular" width="100%" height={400} />
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </AdvancedLayout>
  );
};

export default Export;
