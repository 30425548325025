import { cloneDeep } from 'lodash';

import { Question as ApolloQuestion } from 'graphql/apollo';

import { Question } from 'types/questions';

import { defaultQuestion } from '../constants/defaults';

const assureQuestion = (originalQuestion: ApolloQuestion): Question => {
  const question = cloneDeep(originalQuestion);
  for (const key of Object.keys(defaultQuestion)) {
    if (!question[key]) {
      question[key] = defaultQuestion[key];
    }
  }

  return question as Question;
};

export default assureQuestion;
