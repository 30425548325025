import {
  ClickAwayListener,
  Grid,
  Grow,
  Icon,
  Paper,
  Popper,
  styled,
  Switch,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';

import useSearchState, { SearchOptions } from 'stores/search';

const HtmlTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement="right"
      children={children}
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
  },
}));

interface SearchOptionItem {
  label: string;
  optionKey: SearchOptions;
  disabled?: boolean;
  tip?: ReactNode;
}

const SearchOptionItem: FC<SearchOptionItem> = ({
  label,
  optionKey,
  disabled = false,
  tip,
}) => {
  const { searchOptions, searchOptionsSet } = useSearchState();
  return (
    <HtmlTooltip title={tip}>
      <Grid container alignItems="center">
        <Grid item xs>
          <MDTypography variant="body2">{label}</MDTypography>
        </Grid>
        <Grid item sx={{ width: '60px' }}>
          <Switch
            checked={searchOptions[optionKey]}
            disabled={disabled}
            onChange={() => {
              searchOptionsSet(optionKey, !searchOptions[optionKey]);
              switch (optionKey) {
                case 'phrase':
                  searchOptionsSet('text', !searchOptions[optionKey]);
                  break;
                case 'text':
                  searchOptionsSet('phrase', !searchOptions[optionKey]);
                  break;
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      </Grid>
    </HtmlTooltip>
  );
};

const SearchBoxOptions: FC = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { searchOptions, searchHitCount } = useSearchState();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <MDBox>
      <MDButton
        variant="outlined"
        color={searchHitCount > 0 ? 'secondary' : 'error'}
        iconOnly
        fullWidth
        ref={anchorRef}
        onClick={handleToggle}
      >
        <Icon>settings</Icon>
      </MDButton>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClose}>
                <MDBox pt={1} pr={0} pb={1} pl={2}>
                  <SearchOptionItem
                    label="Phrase Search"
                    optionKey="phrase"
                    tip={
                      <p>
                        Search results will contain the search term exactly as
                        entered. (case sensitive)
                      </p>
                    }
                  />
                  <SearchOptionItem
                    label="Word Search"
                    optionKey="text"
                    tip={
                      <p>
                        Search results will contain one or more words of the
                        search term. (case insensitive)
                      </p>
                    }
                  />
                  <SearchOptionItem
                    label="Wildcard Search"
                    optionKey="wildcard"
                    disabled={!searchOptions.text}
                    tip={
                      <>
                        <p>Only works with a word search.</p>
                        <p>
                          <b>{'?'}</b> {'Matches any single character.'}
                          <br />
                          <b>{'*'}</b> {'Matches 0 or more characters.'}
                          <br />
                          <b>{'/'}</b> {'Escape character.'}
                        </p>
                      </>
                    }
                  />
                </MDBox>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </MDBox>
  );
};

export default SearchBoxOptions;
