import { CoreQuestionType, Question } from '../types/questions';

export const defaultQuestion: Question = {
  _id: '',
  type: '',
  procedures: [],
  text: '',
  rank: '',
  series: [],
  tags: [],
  answers: [
    { text: '', feedback: '', correct: false },
    { text: '', feedback: '', correct: false },
    { text: '', feedback: '', correct: false },
    { text: '', feedback: '', correct: false },
  ],
  feedback: '',
  questionId: 0,
  ro: '',
  created: new Date().toISOString(),
  modified: new Date().toISOString(),
};

export const emptyTrueFalseAnswers = [
  { text: 'True', feedback: '', correct: false },
  { text: 'False', feedback: '', correct: false },
];

export const defaultAutocompletes = {
  ranks: ['sergeant', 'lieutenant', 'captain'],
};

export const defaultCoreType: CoreQuestionType = {
  _id: 'unknown',
  colorName: 'secondary',
  hasAnswerFeedback: false,
  hasGeneralFeedback: false,
  hasImages: false,
  hasMultipleCorrect: false,
  iconName: '',
  isTrueFalse: false,
  label: '',
};
