import { Grid, Icon } from '@mui/material';
import React, { FC } from 'react';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDInput from 'components/MDB/MDInput';
import SearchBoxOptions from 'components/Search/SearchBoxOptions';
import SearchBulkActions from 'components/Search/SearchBulkActions';

import { useSearchState } from 'stores';

const SearchBox: FC<{ onChange: any }> = ({ onChange }) => {
  const { searchTextSet, searchText } = useSearchState();

  return (
    <MDBox
      bgColor="white"
      borderRadius="lg"
      shadow="lg"
      p={2}
      mb={2}
      sx={{
        position: 'sticky',
        top: '100px',
        zIndex: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item style={{ width: '58px' }}>
          <SearchBoxOptions />
        </Grid>

        <Grid item xs>
          <MDInput
            type="search"
            label="Search Term"
            value={searchText}
            autoFocus
            fullWidth
            onChange={({ target }) => searchTextSet(target.value)}
            onKeyUp={({ key }) => key === 'Enter' && onChange()}
          />
        </Grid>

        <Grid item style={{ width: '58px' }}>
          <MDButton
            variant="gradient"
            color="info"
            iconOnly
            fullWidth
            onClick={onChange}
          >
            <Icon>search</Icon>
          </MDButton>
        </Grid>

        <Grid item>
          <SearchBulkActions onChange={onChange} />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default SearchBox;
