import { Card, Grid } from '@mui/material';
import { sample } from 'lodash';
import React, { FC, useState } from 'react';

import AdvancedLayout from 'layouts/AdvancedLayout';

import { SearchFilter } from 'graphql/apollo';
import useQuestion from 'graphql/hooks/useQuestion';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import FacetFilter from 'components/Question/FacetFilter';
import QuickTakeComponent from 'components/Question/QuickTake';

import scrollToTop from 'helpers/scrollToTop';

const QuickTake: FC = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState<
    string | undefined
  >(undefined);
  const [availableQuestions, setAvailableQuestions] = useState<string[]>([]);
  const [answered, setAnswered] = useState(false);

  const { searchQuestionsReturningIds } = useQuestion();

  const nextQuestion = () => {
    scrollToTop();
    const id = sample(availableQuestions);
    setCurrentQuestionId(id);
    setAnswered(false);
    setAvailableQuestions((prevState) =>
      prevState.filter((item) => item != id),
    );
  };

  const startQuickTake = async (filters: SearchFilter) => {
    const ids = await searchQuestionsReturningIds({ ...filters, perPage: 1000 })
      .then((results) => results?.hits || [])
      .then((hits) => hits.filter((hit) => hit?._id).map((hit) => hit?._id));
    const id = sample(ids);
    setCurrentQuestionId(id);
    setAvailableQuestions(ids.filter((item) => item !== id));
  };

  return (
    <AdvancedLayout>
      <Grid container spacing={2}>
        <Grid item sx={{ width: '275px' }}>
          <FacetFilter
            label="Start"
            action={startQuickTake}
            remaining={availableQuestions.length}
          />
        </Grid>
        <Grid item xs>
          <Card>
            <MDBox p={2}>
              <QuickTakeComponent
                id={currentQuestionId}
                onAnswered={() => setAnswered(true)}
              />
              {answered && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <MDButton
                      color="primary"
                      onClick={nextQuestion}
                      variant="outlined"
                    >
                      Next Question
                    </MDButton>
                  </Grid>
                </Grid>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </AdvancedLayout>
  );
};

export default QuickTake;
