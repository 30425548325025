import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import React, { FC, useEffect, useState } from 'react';

import AdvancedLayout from 'layouts/AdvancedLayout';

import { Maybe, SearchResultHit, SearchResultMetum } from 'graphql/apollo';
import { useQuestion } from 'graphql/hooks/useQuestion';

import MDBox from 'components/MDB/MDBox';
import MDTypography from 'components/MDB/MDTypography';
import SearchBox from 'components/Search/SearchBox';
import SearchHit from 'components/Search/SearchHit';
import SearchPagination from 'components/Search/SearchPagination';
import SearchRefinement from 'components/Search/SearchRefinements';

import { useBulkEditState, useGlobalState, useSearchState } from 'stores';

const Search: FC = () => {
  // component state
  const [searchHits, setSearchHits] = useState<Maybe<SearchResultHit>[]>([]);

  // global state
  const { globalLoadingSet } = useGlobalState();
  const { bulkEditSetVisibleSearchResultsIds } = useBulkEditState();
  const { searchFacetsAvailableSet, searchHitCountSet, searchQuery } =
    useSearchState();

  // graphql search
  const { searchQuestions } = useQuestion();

  const performSearch = () => {
    globalLoadingSet(true, 'Searching');
    return searchQuestions(searchQuery())
      .then((results) => (results ? results : {}))
      .then(({ hits, meta }) => {
        setSearchHits(hits ? hits : []);
        bulkEditSetVisibleSearchResultsIds(hits?.map((hit) => hit?._id) || []);
        return meta as SearchResultMetum;
      })
      .then((meta) => {
        const { count, facets } = meta;
        if (facets) {
          if (count && count > 0) {
            for (const [facet, items] of Object.entries(facets)) {
              if (items && items.length > 0) {
                searchFacetsAvailableSet(facet, items || []);
              } else {
                searchFacetsAvailableSet(facet, []);
              }
            }
          }
        }
        searchHitCountSet(count || 0);
      })
      .catch(() => {
        // log some error or do something here
      })
      .then(() => globalLoadingSet(false));
  };

  useEffect(() => {
    // perform initial search
    performSearch();
  }, []);

  return (
    <AdvancedLayout>
      <SearchBox onChange={performSearch} />
      <Grid container spacing={2}>
        <Grid item style={{ width: '270px' }}>
          <Card>
            <MDBox p={2}>
              <SearchRefinement
                facet={'types'}
                label="Type"
                onChange={performSearch}
              />
              <SearchRefinement
                facet={'ranks'}
                label="Rank"
                onChange={performSearch}
              />
              <SearchRefinement
                facet={'series'}
                label="Series"
                onChange={performSearch}
              />
              <SearchRefinement
                facet={'procedures'}
                label="Procedures"
                onChange={performSearch}
              />
              <SearchRefinement
                facet={'tags'}
                label="Tags"
                onChange={performSearch}
              />
              <SearchRefinement
                facet={'ro'}
                label="RO"
                onChange={performSearch}
              />
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs>
          {searchHits.length === 0 ? (
            <MDTypography variant="body1">No Questions Found</MDTypography>
          ) : (
            <SearchPagination onChange={performSearch} />
          )}

          {searchHits.map((hit) => (
            <SearchHit hit={hit} key={hit?._id} onChange={performSearch} />
          ))}

          <SearchPagination />
        </Grid>
      </Grid>
    </AdvancedLayout>
  );
};

export default Search;
