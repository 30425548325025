import { cloneDeep } from 'lodash';
import { FC, useEffect } from 'react';
import { Outlet as RouteOutlet } from 'react-router-dom';

import useFacetsAndTypes from 'graphql/hooks/useFacetsAndTypes';

import { useQuestionState } from 'stores';

const Outlet: FC = () => {
  // graphql core question types
  const { facetsSubscription, getQuestionTypes } = useFacetsAndTypes();
  const { questionTypes, setQuestionFacet, setQuestionType } =
    useQuestionState();

  const { data: facets, loading: facetsLoading } = facetsSubscription();

  useEffect(() => {
    if (!facetsLoading && !!facets)
      if (facets.questionFacets) {
        const receivedFacets = cloneDeep(facets.questionFacets) || {};
        Object.entries(receivedFacets).forEach(([facet, value]) => {
          if (value) {
            setQuestionFacet(
              facet,
              value.map((item) => {
                return {
                  _id: item?._id || 'unknown',
                  count: item?.count || 'unknown',
                };
              }),
            );
          } else {
            setQuestionFacet(facet);
          }
        });
      }
  }, [facetsLoading, facets]);

  useEffect(() => {
    // load dynamic question types if not available
    if (Object.keys(questionTypes || {}).length <= 1) {
      getQuestionTypes().then((coreQuestionTypes) => {
        for (const coreQuestion of cloneDeep(coreQuestionTypes)) {
          if (coreQuestion) {
            setQuestionType(coreQuestion._id, coreQuestion);
          }
        }
      });
    }
  }, [questionTypes]);

  return <RouteOutlet />;
};
export default Outlet;
