import { hookstate, useHookstate } from '@hookstate/core';
import { includes, union } from 'lodash';

interface BulkEditState {
  selectedQuestions: string[];
  visibleSearchResultsIds: string[];
  deleteActive: boolean;
  deleteIds: string[];
}

const bulkEditState = hookstate<BulkEditState>({
  selectedQuestions: [],
  visibleSearchResultsIds: [],
  deleteActive: false,
  deleteIds: [],
});

const useBulkEditState = () => {
  const state = useHookstate(bulkEditState);

  return {
    get bulkEditActive() {
      return !!state.selectedQuestions.get().length;
    },
    get bulkEditSelectedCount() {
      return state.selectedQuestions.get().length;
    },
    get bulkEditSelectedQuestions() {
      return state.selectedQuestions.get();
    },
    get bulkEditDeleteActive() {
      return state.deleteActive.get();
    },
    get bulkEditDeleteIds() {
      return state.deleteIds.get();
    },
    bulkEditToggleQuestion(questionId: string) {
      if (includes(state.selectedQuestions.get(), questionId)) {
        //remove
        state.selectedQuestions.set(
          state.selectedQuestions.get().filter((item) => item !== questionId),
        );
      } else {
        //add
        state.selectedQuestions.set([
          ...state.selectedQuestions.get(),
          questionId,
        ]);
      }
    },
    bulkEditClearSelectedQuestions() {
      state.selectedQuestions.set([]);
    },
    bulkEditSetVisibleSearchResultsIds(ids: string[]) {
      state.visibleSearchResultsIds.set(ids);
    },
    bulkEditSetSelectVisibleSearchResults() {
      const visible = state.visibleSearchResultsIds.get();
      const current = state.selectedQuestions.get();
      state.selectedQuestions.set(union(visible, current));
    },
  };
};

export default useBulkEditState;
