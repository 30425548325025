import { Page, StyleSheet, View } from '@react-pdf/renderer';
import { clone } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import Html from 'react-pdf-html';

import { Question } from 'types/questions';

const pageHtml = `
<html>
<head>
    <title>Exports</title>
    <style>
      * { font-family: 'Helvetica', serif; font-size: 12pt }
      p { margin: 0 0 10px }
      li { width: 90%}
    </style>
</head>
<body>
<p>Database ID: {{ID}}<br/>
   Question ID: {{QUESTION_ID}}<br/>
   Type: {{TYPE}}<br/>
   Series: {{SERIES}}<br/>
   Procedures: {{PROCEDURES}}<br/>
   Tags: {{TAGS}}
</p>
{{QUESTION}}
<ul>{{ANSWER}}</ul>
{{FEEDBACK}}
</body>
</html>
`;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 12,
    fontFamily: 'Helvetica',
    padding: 15,
  },
  entryContainer: {
    marginBottom: 10,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
});

const PageExport: FC<{ question?: Question }> = ({ question }) => {
  const [displayQuestion, setDisplayQuestion] = useState('');

  useEffect(() => {
    let display = clone(pageHtml);
    let answerDisplay = '';

    display = display.replace('{{ID}}', question?._id || '');
    display = display.replace(
      '{{QUESTION_ID}}',
      question?.questionId.toString() || '',
    );
    display = display.replace('{{TYPE}}', question?.type || '');
    display = display.replace('{{SERIES}}', question?.series.join(', ') || '');
    display = display.replace(
      '{{PROCEDURES}}',
      question?.procedures.join(', ') || '',
    );
    display = display.replace('{{TAGS}}', question?.tags.join(', ') || '');
    display = display.replace('{{QUESTION}}', question?.text || '');
    display = display.replace('{{FEEDBACK}}', question?.feedback || '');

    for (const answer of question?.answers || []) {
      answerDisplay += '<li>';
      answerDisplay += answer?.text || '' + answer?.feedback || '';
      answerDisplay += answer?.feedback || '';
      answerDisplay += '</li>';
    }
    display = display.replace('{{ANSWER}}', answerDisplay);

    setDisplayQuestion(display);
  }, [question]);

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.entryContainer}>
        <Html>{displayQuestion}</Html>
      </View>
    </Page>
  );
};

export default PageExport;
