// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FC, ReactNode } from 'react';

import MDTypography from 'components/MDB/MDTypography';

import MDBox from '../MDB/MDBox';

// Material Dashboard 2 PRO React TS components

interface Props {
  color?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark';
  count?: string | number;
  title: ReactNode;
  description: string;
  [key: string]: any;
}

const QuestionInfoCard: FC<Props> = ({ color, count, title }) => {
  return (
    <Card>
      <MDBox p={2}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <MDBox
              p={1}
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="6rem"
              height="3rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            >
              {count}
            </MDBox>
          </Grid>
          <Grid item>
            <MDTypography
              variant="h4"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// Declaring default props for MiniInfoCard
QuestionInfoCard.defaultProps = {
  color: 'info',
};

export default QuestionInfoCard;
