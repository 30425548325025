import { ImmutableArray } from '@hookstate/core';
import { cloneDeep } from 'lodash';

import {
  useDeleteTrashMutation,
  useGetTrashByIdLazyQuery,
  useGetTrashesLazyQuery,
} from '../apollo';

export const useTrash = () => {
  const [performGetAllTrash] = useGetTrashesLazyQuery();
  const [performGetTrash] = useGetTrashByIdLazyQuery();
  const [removeTrash] = useDeleteTrashMutation();

  const deleteTrash = (ids: string[] | ImmutableArray<string>) =>
    removeTrash({
      variables: {
        ids,
      },
    }).then(({ data }) => data?.deleteManyTrashes || []);

  const getTrashes = () =>
    performGetAllTrash({
      fetchPolicy: 'network-only',
    })
      .then(({ data }) => data?.trashes)
      .then((trashes) => cloneDeep(trashes || []));

  const getTrash = (id: string) =>
    performGetTrash({ variables: { id } })
      .then(({ data }) => data?.trash)
      .then((trashes) => cloneDeep(trashes));

  return {
    getTrashes,
    deleteTrash,
    getTrash,
  };
};
export default useTrash;
