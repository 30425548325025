import { Card, Chip, Grid, Icon, Tooltip } from '@mui/material';
import { Parser } from 'html-to-react';
import log from 'loglevel';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';

import { isValidColor } from 'utils/colors';

import { QuestionColor } from 'constants/colors';

import { useQuestionState } from 'stores';

import useQuestion from '../../graphql/hooks/useQuestion';
import useTrash from '../../graphql/hooks/useTrash';
import { prettyDate } from '../../helpers';
import ActionDialog from '../ActionDialog';

interface Props {
  id: string;
  text: string;
  type: string;
  date: string;
}

const TrashItem: FC<Props> = ({ id, text, type, date }) => {
  const [pending, setPending] = useState(false);
  const [hitColor, setHitColor] = useState<QuestionColor>('secondary');
  const [hitTypeLabel, setHitTypeLabel] = useState('');
  const [confirm, setConfirm] = useState(false);

  const navigate = useNavigate();
  const { questionTypes } = useQuestionState();
  const { addQuestion } = useQuestion();
  const { getTrash, deleteTrash } = useTrash();

  useEffect(() => {
    if (questionTypes && questionTypes[type]) {
      if (isValidColor(questionTypes[type].colorName as string)) {
        setHitColor(questionTypes[type].colorName as unknown as QuestionColor);
      }
      setHitTypeLabel(
        questionTypes[type].label ? questionTypes[type].label : type,
      );
    }
  }, [questionTypes]);

  const handleRestore = async () => {
    setPending(true);
    try {
      const trash = await getTrash(id);
      if (trash) {
        const { _id, ...rest } = trash;
        const restoredId = await addQuestion({
          ...rest,
          modified: new Date().toISOString(),
        }).then((result) => result?._id);
        if (restoredId) {
          deleteTrash([id]);
          navigate(`/questions/edit/${restoredId}`);
        }
      }
    } catch (e) {
      log.error(e);
    }
    setPending(false);
  };

  return (
    <MDBox mb={2}>
      <Card>
        <MDBox p={2} pt={1}>
          <Grid
            container
            spacing={2}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Chip
                label={
                  <MDTypography
                    variant="caption"
                    textTransform="uppercase"
                    fontWeight="medium"
                    color={'white'}
                  >
                    {hitTypeLabel}
                  </MDTypography>
                }
                color={hitColor as QuestionColor}
                size="small"
              />
            </Grid>
            <Grid item>
              <MDTypography
                variant="overline"
                textTransform="uppercase"
                fontWeight="medium"
              >
                Deleted: {prettyDate(date)}
              </MDTypography>
              <Tooltip title="Restore" placement="bottom">
                <MDButton
                  circular
                  iconOnly
                  disabled={pending}
                  color={hitColor}
                  variant="text"
                  onClick={() => setConfirm(true)}
                >
                  <Icon>restore</Icon>
                </MDButton>
              </Tooltip>
            </Grid>
          </Grid>
          <MDTypography variant="body2" componenet="div">
            {Parser().parse(text)}
          </MDTypography>
        </MDBox>
      </Card>
      <ActionDialog
        onAction={handleRestore}
        actionLabel="Restore"
        onClose={() => setConfirm(false)}
        open={confirm}
        message={text}
        title={'Restore the following Question?'}
      ></ActionDialog>
    </MDBox>
  );
};

export default TrashItem;
