import { Immutable, ImmutableArray } from '@hookstate/core';
import { ObjectId } from 'bson';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import {
  FacetType,
  Question,
  QuestionInsertInput,
  QuestionUpdateInput,
  SearchFilter,
  Trash,
  TrashInsertInput,
  useAddManyTrashesMutation,
  useAddQuestionMutation,
  useAddQuestionsMutation,
  useDeleteQuestionsMutation,
  useGetQuestionByIdLazyQuery,
  useGetQuestionFacetsAndCountsQuery,
  useGetQuestionsByIdLazyQuery,
  useGetQuestionsBySeriesLazyQuery,
  useSearchQuestionsLazyQuery,
  useSearchQuestionsReturningIdsLazyQuery,
  useUpdateManyQuestionsMutation,
  useUpdateQuestionMutation,
} from 'graphql/apollo';

/**
 * useQuestion Hook
 * @memberof Hooks
 * @alias useQuestion
 */
export const useQuestion = () => {
  const [performSearch] = useSearchQuestionsLazyQuery();
  const [performSearchReturningIds] = useSearchQuestionsReturningIdsLazyQuery();
  const [performGetQuestion] = useGetQuestionByIdLazyQuery();
  const [performGetQuestions] = useGetQuestionsByIdLazyQuery();
  const [performGetQuestionsById] = useGetQuestionsBySeriesLazyQuery();
  const [addManyTrashesMutation] = useAddManyTrashesMutation();
  const [addQuestionMutation] = useAddQuestionMutation();
  const [addQuestionsMutation] = useAddQuestionsMutation();
  const [updateQuestionsMutation] = useUpdateManyQuestionsMutation();
  const [updateQuestionMutation] = useUpdateQuestionMutation();
  const [deleteManyQuestionsMutation] = useDeleteQuestionsMutation();

  const addQuestion = (
    question: QuestionInsertInput | Immutable<QuestionInsertInput>,
  ) =>
    addQuestionMutation({
      variables: {
        question: {
          ...question,
          modified: new Date().toISOString(),
        } as QuestionInsertInput,
      },
    }).then(({ data }) => data?.addedQuestion);

  const addQuestions = (
    questions: Question[] | ImmutableArray<QuestionInsertInput>,
  ) => {
    const qta = questions.map((question) => {
      const { _id, questionId, ...rest } = question;
      return { ...rest, modified: new Date().toISOString() };
    });

    return addQuestionsMutation({
      variables: {
        questions: qta,
      },
    }).then(({ data }) => data?.addedQuestions?.insertedIds);
  };

  const deleteQuestions = async (ids: string[] | ImmutableArray<string>) => {
    const questions = await performGetQuestions({ variables: { ids } })
      .then(({ data }) => data?.questions)
      .then((questions) => (questions ? cloneDeep(questions) : undefined));

    if (questions) {
      for (const question of questions as Trash[]) {
        question.deleted = new Date().toISOString();
      }
      const trashes = await addManyTrashesMutation({
        variables: {
          trashes: questions as TrashInsertInput[],
        },
      })
        .then(({ data }) => data?.addedTrashes)
        .then((response) => !!response?.insertedIds);

      if (trashes) {
        return deleteManyQuestionsMutation({
          variables: { ids },
        }).then(({ data }) => data?.deleteManyQuestions?.deletedCount);
      }
    }

    return undefined;
  };

  const deleteQuestion = async (id: string) => deleteQuestions([id]);

  const duplicateQuestion: (
    id: string,
  ) => Promise<{ _id: string | undefined }> = async (id: string) => {
    if (id) {
      const {
        _id: originalId,
        questionId,
        ...bare
      } = (await getQuestion(id)) || {};
      const { _id } = await addQuestion({
        ...bare,
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
      }).then((question) => question || {});
      return { _id };
    }
    return { _id: undefined };
  };

  const getFacets = () => {
    const [facets, setFacets] = useState<{
      types: FacetType[];
      ranks: FacetType[];
      series: FacetType[];
      procedures: FacetType[];
      tags: FacetType[];
      ro: FacetType[];
    }>({
      types: [],
      ranks: [],
      series: [],
      procedures: [],
      tags: [],
      ro: [],
    });

    const { data, loading } = useGetQuestionFacetsAndCountsQuery({
      skip: false,
      fetchPolicy: 'no-cache',
    });

    useEffect(() => {
      if (!loading && !!data) {
        setFacets({
          types: data.questionFacets?.types as FacetType[],
          ranks: data.questionFacets?.ranks as FacetType[],
          series: data.questionFacets?.series as FacetType[],
          procedures: data.questionFacets?.procedures as FacetType[],
          tags: data.questionFacets?.tags as FacetType[],
          ro: data.questionFacets?.ro as FacetType[],
        });
      }
    }, [data, loading]);

    return facets;
  };

  const getQuestion = (id) =>
    performGetQuestion({ variables: { id }, fetchPolicy: 'no-cache' }).then(
      ({ data }) => data?.question,
    );

  const getQuestionsBySeries = (series: string, type?: string) => {
    const options: {
      variables: {
        series: string[];
        type?: string;
      };
      fetchPolicy: 'no-cache';
    } = { variables: { series: [series] }, fetchPolicy: 'no-cache' };
    if (type) {
      options.variables.type = type;
    }

    return performGetQuestionsById(options).then(({ data }) =>
      cloneDeep(data?.questions),
    );
  };

  const searchQuestions = (query: SearchFilter) =>
    performSearch({
      variables: { query },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => data?.questionSearch);

  const searchQuestionsReturningIds = (query: SearchFilter) =>
    performSearchReturningIds({
      variables: { query },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => data?.questionSearch);

  const updateQuestion = (questionId: string, updates: QuestionUpdateInput) =>
    updateQuestionMutation({
      variables: {
        questionId: new ObjectId(questionId),
        updates: { ...updates, modified: new Date().toISOString() },
      },
    }).then(({ data }) => data?.updatedQuestion);

  /**
   * Updates a group of questions with a new data set.
   *
   * @param ids
   * @param set
   * @return {{matchedCount: number, modifiedCount: number} | null}}
   */
  const updateQuestions = (ids: string[] | ImmutableArray<string>, set) =>
    updateQuestionsMutation({
      variables: {
        ids: ids,
        set: { ...set, modified: new Date().toISOString() },
      },
    }).then(({ data }) => data?.updateManyQuestions);

  const cloneSeries = async (
    source: string,
    target: string[],
    type?: string,
  ) => {
    const questions = await getQuestionsBySeries(source, type);
    const cleanedQuestions: Question[] = [];
    if (questions) {
      if (questions.length >= 1000) {
        throw Error('More than 1000 questions found, cannot clone.');
      }

      for (const question of questions) {
        const { _id, questionId, ...rest } = question || {};

        cleanedQuestions.push({
          ...rest,
          series: target,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
        });
      }
      return addQuestions(cleanedQuestions);
    }
    return [];
  };

  return {
    addQuestion,
    addQuestions,
    cloneSeries,
    deleteQuestion,
    deleteQuestions,
    duplicateQuestion,
    getFacets,
    getQuestion,
    getQuestionsBySeries,
    searchQuestions,
    searchQuestionsReturningIds,
    updateQuestion,
    updateQuestions,
  };
};

export default useQuestion;
