import Icon from '@mui/material/Icon';

import Dashboard from './pages/Dashboard';
import Create from './pages/questions/Create';
import Edit from './pages/questions/Edit';
import Export from './pages/questions/Export';
import Outlet from './pages/questions/Outlet';
import QuickTake from './pages/questions/QuickTake';
import Search from './pages/questions/Search';
import Trash from './pages/questions/Trash';

const newRoutes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
    noCollapse: true,
    hidden: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Daily Question',
  //   key: 'users',
  //   href: 'https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md',
  //   icon: <Icon fontSize="medium">people_alt</Icon>,
  //   noCollapse: true,
  // },
  // { type: 'divider', key: 'divider-1' },
  // {
  //   type: 'collapse',
  //   name: 'Digital ID',
  //   key: 'digitalid',
  //   icon: <Icon fontSize="medium">assignment_ind</Icon>,
  //   collapse: [
  //     {
  //       name: 'Notifications',
  //       key: 'notifications',
  //       route: '/ecommerce/products/new-product',
  //       component: <></>,
  //     },
  //     {
  //       name: 'Configuration',
  //       key: 'did-configuration',
  //       route: '/ecommerce/products/edit-product',
  //       component: <></>,
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'ELF',
  //   key: 'elf',
  //   icon: <Icon fontSize="medium">app_shortcut</Icon>,
  //   collapse: [
  //     {
  //       name: 'Configuration',
  //       key: 'configuration',
  //       href: '',
  //     },
  //     {
  //       name: 'Exams',
  //       key: 'exams',
  //       href: '',
  //     },
  //     {
  //       name: 'Students',
  //       key: 'students',
  //       href: '',
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'LMS',
  //   key: 'lms',
  //   icon: <Icon fontSize="medium">school</Icon>,
  //   collapse: [
  //     {
  //       name: 'Charges',
  //       key: 'charges',
  //       href: 'https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/',
  //     },
  //     {
  //       name: 'Students',
  //       key: 'students',
  //       href: 'https://www.creative-tim.com/learning-lab/react/license/material-dashboard/',
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'Elite LF',
  //   key: 'elf',
  //   icon: <Icon fontSize="medium">extension</Icon>,
  //   collapse: [
  //     {
  //       name: 'Configuration',
  //       key: 'configuration',
  //       route: '/elf/configuration',
  //       component: <Configuration />,
  //     },
  //   ],
  // },
  {
    type: 'collapse',
    name: 'Questions',
    key: 'questions',
    icon: <Icon fontSize="medium">quiz</Icon>,
    component: <Outlet />,
    collapse: [
      {
        name: 'Search',
        key: 'search',
        route: '/questions/search',
        component: <Search />,
      },
      {
        name: 'Create',
        key: 'create',
        route: '/questions/create',
        component: <Create />,
      },
      {
        name: 'Edit',
        key: 'edit',
        route: '/questions/edit/:id',
        component: <Edit />,
        hidden: true,
      },
      {
        name: 'Quick Take',
        key: 'quick-take',
        route: '/questions/quick-take',
        component: <QuickTake />,
      },
      {
        name: 'Export',
        key: 'export',
        route: '/questions/export',
        component: <Export />,
      },
      {
        name: 'Trash',
        key: 'trash',
        route: '/questions/trash',
        component: <Trash />,
      },
    ],
  },

  // { type: 'divider', key: 'divider-1' },
  // {
  //   type: 'collapse',
  //   name: 'Users',
  //   key: 'users',
  //   route: '/users',
  //   component: <Users />,
  //   icon: <Icon fontSize="medium">people_alt</Icon>,
  //   noCollapse: true,
  // },
];

export default newRoutes;
