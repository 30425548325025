import {
  Alert,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StepContent,
  Stepper,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isArray, isObject, uniqWith } from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import { useQuestion } from 'graphql/hooks/useQuestion';

import AutocompleteField from 'components/Forms/Autocomplete';
import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';

import { useBulkEditState, useNotifications, useQuestionState } from 'stores';

import theme from 'assets/theme';

const originalTheme = createTheme();

const OriginalThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <ThemeProvider theme={originalTheme}>{children}</ThemeProvider>;
};

const NewThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

interface SearchBulkEditDialogProps {
  open?: boolean;
  onClose: () => void;
}

type DropdownItem = { label: string; value: string };

type NewFieldValues =
  | string
  | string[]
  | DropdownItem
  | DropdownItem[]
  | undefined;

const availableFieldsForEdit = ['type', 'series', 'procedures', 'tags', 'io'];

// A custom theme for this app
const SearchEdit: FC<SearchBulkEditDialogProps> = ({
  open = false,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedField, setSelectedField] = useState<string | undefined>(
    undefined,
  );
  const [newFieldValue, setNewFieldValue] = useState<NewFieldValues>(undefined);
  const [fieldValueOptions, setFieldValueOptions] = useState<any>([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setIsOpen(open);
    setNewFieldValue(undefined);
    setSelectedField(undefined);
    setActiveStep(0);
  }, [open]);

  const { getFacets, updateQuestions } = useQuestion();
  const { bulkEditSelectedQuestions, bulkEditSelectedCount } =
    useBulkEditState();
  const { addNotification } = useNotifications();

  // const { questionType } = getQuestionType(question.type || '', true);
  // const { questionTypes } = getQuestionTypes();
  const facets = getFacets();
  const { questionTypes } = useQuestionState();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUpdates = () => {
    if (selectedField && newFieldValue) {
      setProcessing(true);
      let newValue: string | string[] = '';

      if (isArray(newFieldValue)) {
        newValue = newFieldValue.map((item) =>
          typeof item === 'string' ? item : item.label,
        );
      } else if (isObject(newFieldValue)) {
        newValue = newFieldValue.value;
      } else {
        newValue = newFieldValue;
      }

      updateQuestions(bulkEditSelectedQuestions, {
        [selectedField]: newValue,
      })
        .then(
          (response) =>
            addNotification({
              message: `Found ${response?.matchedCount} questions, ${response?.modifiedCount} of which were updated.`,
            }),
          //
        )
        .then(() => setProcessing(false))
        .then(() => setIsOpen(false))
        .then(() => onClose && onClose());
    }
  };

  useEffect(() => {
    switch (selectedField) {
      case 'type': {
        let types: any = [];
        if (questionTypes) {
          types = Object.values(questionTypes).map(({ label, _id: value }) => ({
            label,
            value,
          }));
        }

        return setFieldValueOptions(types);
      }
      case 'series': {
        const series = facets.series.map((series) => series._id) || [];
        return setFieldValueOptions(
          uniqWith(series, (a, b) => a?.toLowerCase() === b?.toLowerCase()),
        );
      }
      case 'procedures': {
        const procedures =
          facets.procedures.map((procedure) => procedure._id) || [];
        return setFieldValueOptions(
          uniqWith(procedures, (a, b) => a?.toLowerCase() === b?.toLowerCase()),
        );
      }
      case 'tags': {
        const tags = facets.tags.map((tag) => tag._id) || [];
        return setFieldValueOptions(
          uniqWith(tags, (a, b) => a?.toLowerCase() === b?.toLowerCase()),
        );
      }
      default:
        setFieldValueOptions([]);
    }

    setFieldValueOptions(
      facets.types ? facets?.types?.map((type) => type._id) || [] : [],
    );
  }, [selectedField]);

  const currentStepIsValid = () => {
    switch (activeStep) {
      case 0:
        return selectedField != undefined;
      case 1:
        return newFieldValue != undefined;
      case 2:
        return selectedField != undefined && newFieldValue != undefined;
      default:
        return false;
    }
  };

  const displayNewValue = (value: NewFieldValues): string => {
    if (Array.isArray(value)) {
      return value
        .map((item) => (typeof item === 'string' ? item : item.label))
        .join(', ');
    }
    return value ? (typeof value === 'string' ? value : value.label) : '';
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
        setIsOpen(false);
      }}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        Modify Question{bulkEditSelectedCount > 1 && 's'}
      </DialogTitle>
      <DialogContent>
        <OriginalThemeProvider>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={0}>
              <StepLabel
                optional={
                  activeStep !== 0 && selectedField != undefined
                    ? capitalize(selectedField)
                    : null
                }
              >
                Select Field
              </StepLabel>
              <StepContent>
                Select a field you would like to update on the selected
                questions.
                <NewThemeProvider>
                  <AutocompleteField
                    label="Field"
                    error={false}
                    helpText={''}
                    value={selectedField}
                    onSelect={(selected) => {
                      setSelectedField(selected);
                      setNewFieldValue(undefined);
                    }}
                    options={availableFieldsForEdit}
                    disableClearable
                  />
                </NewThemeProvider>
              </StepContent>
            </Step>
            <Step key={1}>
              <StepLabel
                optional={
                  activeStep !== 1 && newFieldValue != undefined ? (
                    <MDTypography variant="body2">
                      {displayNewValue(newFieldValue)}
                    </MDTypography>
                  ) : null
                }
              >
                Select New Value
              </StepLabel>
              <StepContent>
                <MDTypography variant="body2">
                  Select the new value for "{capitalize(selectedField || '')}".
                </MDTypography>
                <NewThemeProvider>
                  <AutocompleteField
                    label="Value"
                    error={false}
                    helpText={''}
                    value={newFieldValue}
                    onSelect={(selected) =>
                      setNewFieldValue(
                        selected.map((item) => item.toLowerCase()),
                      )
                    }
                    options={fieldValueOptions}
                    multiple={selectedField !== 'type'}
                    freeSolo
                    disableClearable
                  />
                </NewThemeProvider>
              </StepContent>
            </Step>
            <Step key={2}>
              <StepLabel>Summary</StepLabel>
              <StepContent>
                <MDBox mb={2}>
                  <MDTypography variant="body2">
                    <strong>{capitalize(selectedField || '')}</strong> will be
                    updated with{' '}
                    <strong>{displayNewValue(newFieldValue)}</strong> for{' '}
                    <strong>{bulkEditSelectedCount}</strong> selected questions.
                  </MDTypography>
                </MDBox>
              </StepContent>
            </Step>
          </Stepper>
          {activeStep === 2 && (
            <Alert severity="warning">
              Selecting "Finish" will perform this change.
              <br />
              You will not be able to revert or undo this change.
            </Alert>
          )}
        </OriginalThemeProvider>
      </DialogContent>
      <DialogActions>
        <MDButton
          disabled={activeStep === 0 || processing}
          onClick={handleBack}
          sx={{ mt: 1, mr: 1 }}
        >
          Back
        </MDButton>
        {activeStep === 2 && (
          <MDButton
            color="secondary"
            disabled={processing}
            onClick={() => setIsOpen(false)}
            sx={{ mt: 1, mr: 1 }}
            autoFocus
          >
            Cancel
          </MDButton>
        )}
        <MDButton
          disabled={!currentStepIsValid() || processing}
          color="info"
          onClick={activeStep === 2 ? handleUpdates : handleNext}
          sx={{ mt: 1, mr: 1 }}
        >
          {processing && (
            <CircularProgress
              size={24}
              sx={{
                color: grey[100],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}

          {activeStep === 2 ? 'Finish' : 'Continue'}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default SearchEdit;
