import { isValid } from 'date-fns';
import { format } from 'date-fns-tz';
import log from 'loglevel';

const prettyDate = (dateString: string, timeZone?, pattern?) => {
  const outputPattern = pattern ? pattern : "MMMM do, yyyy 'at' H:mm (zzzz)";
  const parsedDate = new Date(dateString);

  if (!isValid(parsedDate)) {
    return `${dateString} (Invalid Date/Time)`;
  }

  try {
    return format(parsedDate, outputPattern, timeZone ? { timeZone } : {});
  } catch (e: unknown) {
    if (e instanceof Error) {
      log.warn(e.message);
    }
  }

  try {
    return format(parsedDate, outputPattern);
  } catch (e: unknown) {
    if (e instanceof Error) {
      log.warn(e.message);
    }
  }

  return `${dateString} (Invalid Date/Time)`;
};

export default prettyDate;
