import React, { createContext, FC, ReactNode, useContext } from 'react';
import { App } from 'realm-web';

import { REALM_APP_ID } from '../constants';

interface Props {
  children: ReactNode;
}

export const RealmAppContext = createContext<any>({});

const RealmAppProvider: FC<Props> = ({ children }) => {
  const [app] = React.useState(new App(REALM_APP_ID));

  return (
    <RealmAppContext.Provider value={app}>{children}</RealmAppContext.Provider>
  );
};

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);

  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`,
    );
  }
  return app;
};

export default RealmAppProvider;
