import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Long: { input: any; output: any; }
  ObjectId: { input: any; output: any; }
};

export type CoreCounter = {
  _id?: Maybe<CoreCounter_Id>;
  seq_value?: Maybe<Scalars['Long']['output']>;
};

export type CoreCounterInsertInput = {
  _id?: InputMaybe<CoreCounter_IdInsertInput>;
  seq_value?: InputMaybe<Scalars['Long']['input']>;
};

export type CoreCounterQueryInput = {
  AND?: InputMaybe<Array<CoreCounterQueryInput>>;
  OR?: InputMaybe<Array<CoreCounterQueryInput>>;
  _id?: InputMaybe<CoreCounter_IdQueryInput>;
  _id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seq_value?: InputMaybe<Scalars['Long']['input']>;
  seq_value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seq_value_gt?: InputMaybe<Scalars['Long']['input']>;
  seq_value_gte?: InputMaybe<Scalars['Long']['input']>;
  seq_value_in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  seq_value_lt?: InputMaybe<Scalars['Long']['input']>;
  seq_value_lte?: InputMaybe<Scalars['Long']['input']>;
  seq_value_ne?: InputMaybe<Scalars['Long']['input']>;
  seq_value_nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
};

export enum CoreCounterSortByInput {
  SEQ_VALUE_ASC = 'SEQ_VALUE_ASC',
  SEQ_VALUE_DESC = 'SEQ_VALUE_DESC'
}

export type CoreCounterUpdateInput = {
  _id?: InputMaybe<CoreCounter_IdUpdateInput>;
  _id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  seq_value?: InputMaybe<Scalars['Long']['input']>;
  seq_value_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoreCounter_Id = {
  coll?: Maybe<Scalars['String']['output']>;
  db?: Maybe<Scalars['String']['output']>;
};

export type CoreCounter_IdInsertInput = {
  coll?: InputMaybe<Scalars['String']['input']>;
  db?: InputMaybe<Scalars['String']['input']>;
};

export type CoreCounter_IdQueryInput = {
  AND?: InputMaybe<Array<CoreCounter_IdQueryInput>>;
  OR?: InputMaybe<Array<CoreCounter_IdQueryInput>>;
  coll?: InputMaybe<Scalars['String']['input']>;
  coll_exists?: InputMaybe<Scalars['Boolean']['input']>;
  coll_gt?: InputMaybe<Scalars['String']['input']>;
  coll_gte?: InputMaybe<Scalars['String']['input']>;
  coll_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coll_lt?: InputMaybe<Scalars['String']['input']>;
  coll_lte?: InputMaybe<Scalars['String']['input']>;
  coll_ne?: InputMaybe<Scalars['String']['input']>;
  coll_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  db?: InputMaybe<Scalars['String']['input']>;
  db_exists?: InputMaybe<Scalars['Boolean']['input']>;
  db_gt?: InputMaybe<Scalars['String']['input']>;
  db_gte?: InputMaybe<Scalars['String']['input']>;
  db_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  db_lt?: InputMaybe<Scalars['String']['input']>;
  db_lte?: InputMaybe<Scalars['String']['input']>;
  db_ne?: InputMaybe<Scalars['String']['input']>;
  db_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CoreCounter_IdUpdateInput = {
  coll?: InputMaybe<Scalars['String']['input']>;
  coll_unset?: InputMaybe<Scalars['Boolean']['input']>;
  db?: InputMaybe<Scalars['String']['input']>;
  db_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoreQuestionType = {
  _id: Scalars['String']['output'];
  colorName: Scalars['String']['output'];
  hasAnswerFeedback: Scalars['Boolean']['output'];
  hasGeneralFeedback: Scalars['Boolean']['output'];
  hasImages: Scalars['Boolean']['output'];
  hasMultipleCorrect: Scalars['Boolean']['output'];
  iconName: Scalars['String']['output'];
  isTrueFalse: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type CoreQuestionTypeInsertInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  colorName: Scalars['String']['input'];
  hasAnswerFeedback: Scalars['Boolean']['input'];
  hasGeneralFeedback: Scalars['Boolean']['input'];
  hasImages: Scalars['Boolean']['input'];
  hasMultipleCorrect: Scalars['Boolean']['input'];
  iconName: Scalars['String']['input'];
  isTrueFalse: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
};

export type CoreQuestionTypeQueryInput = {
  AND?: InputMaybe<Array<CoreQuestionTypeQueryInput>>;
  OR?: InputMaybe<Array<CoreQuestionTypeQueryInput>>;
  _id?: InputMaybe<Scalars['String']['input']>;
  _id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt?: InputMaybe<Scalars['String']['input']>;
  _id_gte?: InputMaybe<Scalars['String']['input']>;
  _id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _id_lt?: InputMaybe<Scalars['String']['input']>;
  _id_lte?: InputMaybe<Scalars['String']['input']>;
  _id_ne?: InputMaybe<Scalars['String']['input']>;
  _id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colorName?: InputMaybe<Scalars['String']['input']>;
  colorName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  colorName_gt?: InputMaybe<Scalars['String']['input']>;
  colorName_gte?: InputMaybe<Scalars['String']['input']>;
  colorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colorName_lt?: InputMaybe<Scalars['String']['input']>;
  colorName_lte?: InputMaybe<Scalars['String']['input']>;
  colorName_ne?: InputMaybe<Scalars['String']['input']>;
  colorName_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasAnswerFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnswerFeedback_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnswerFeedback_ne?: InputMaybe<Scalars['Boolean']['input']>;
  hasGeneralFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  hasGeneralFeedback_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hasGeneralFeedback_ne?: InputMaybe<Scalars['Boolean']['input']>;
  hasImages?: InputMaybe<Scalars['Boolean']['input']>;
  hasImages_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hasImages_ne?: InputMaybe<Scalars['Boolean']['input']>;
  hasMultipleCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  hasMultipleCorrect_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hasMultipleCorrect_ne?: InputMaybe<Scalars['Boolean']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iconName_gt?: InputMaybe<Scalars['String']['input']>;
  iconName_gte?: InputMaybe<Scalars['String']['input']>;
  iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iconName_lt?: InputMaybe<Scalars['String']['input']>;
  iconName_lte?: InputMaybe<Scalars['String']['input']>;
  iconName_ne?: InputMaybe<Scalars['String']['input']>;
  iconName_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isTrueFalse?: InputMaybe<Scalars['Boolean']['input']>;
  isTrueFalse_exists?: InputMaybe<Scalars['Boolean']['input']>;
  isTrueFalse_ne?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_gt?: InputMaybe<Scalars['String']['input']>;
  label_gte?: InputMaybe<Scalars['String']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_lt?: InputMaybe<Scalars['String']['input']>;
  label_lte?: InputMaybe<Scalars['String']['input']>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum CoreQuestionTypeSortByInput {
  COLORNAME_ASC = 'COLORNAME_ASC',
  COLORNAME_DESC = 'COLORNAME_DESC',
  ICONNAME_ASC = 'ICONNAME_ASC',
  ICONNAME_DESC = 'ICONNAME_DESC',
  LABEL_ASC = 'LABEL_ASC',
  LABEL_DESC = 'LABEL_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type CoreQuestionTypeUpdateInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  _id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  colorName?: InputMaybe<Scalars['String']['input']>;
  colorName_unset?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnswerFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnswerFeedback_unset?: InputMaybe<Scalars['Boolean']['input']>;
  hasGeneralFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  hasGeneralFeedback_unset?: InputMaybe<Scalars['Boolean']['input']>;
  hasImages?: InputMaybe<Scalars['Boolean']['input']>;
  hasImages_unset?: InputMaybe<Scalars['Boolean']['input']>;
  hasMultipleCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  hasMultipleCorrect_unset?: InputMaybe<Scalars['Boolean']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconName_unset?: InputMaybe<Scalars['Boolean']['input']>;
  isTrueFalse?: InputMaybe<Scalars['Boolean']['input']>;
  isTrueFalse_unset?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteManyPayload = {
  deletedCount: Scalars['Int']['output'];
};

export type Export = {
  _id?: Maybe<Scalars['ObjectId']['output']>;
  archive?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ExportInsertInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  archive?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ExportQueryInput = {
  AND?: InputMaybe<Array<ExportQueryInput>>;
  OR?: InputMaybe<Array<ExportQueryInput>>;
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  archive?: InputMaybe<Scalars['String']['input']>;
  archive_exists?: InputMaybe<Scalars['Boolean']['input']>;
  archive_gt?: InputMaybe<Scalars['String']['input']>;
  archive_gte?: InputMaybe<Scalars['String']['input']>;
  archive_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  archive_lt?: InputMaybe<Scalars['String']['input']>;
  archive_lte?: InputMaybe<Scalars['String']['input']>;
  archive_ne?: InputMaybe<Scalars['String']['input']>;
  archive_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created?: InputMaybe<Scalars['String']['input']>;
  created_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_gt?: InputMaybe<Scalars['String']['input']>;
  created_gte?: InputMaybe<Scalars['String']['input']>;
  created_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_lt?: InputMaybe<Scalars['String']['input']>;
  created_lte?: InputMaybe<Scalars['String']['input']>;
  created_ne?: InputMaybe<Scalars['String']['input']>;
  created_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_exists?: InputMaybe<Scalars['Boolean']['input']>;
  filename_gt?: InputMaybe<Scalars['String']['input']>;
  filename_gte?: InputMaybe<Scalars['String']['input']>;
  filename_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename_lt?: InputMaybe<Scalars['String']['input']>;
  filename_lte?: InputMaybe<Scalars['String']['input']>;
  filename_ne?: InputMaybe<Scalars['String']['input']>;
  filename_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  rank_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rank_gt?: InputMaybe<Scalars['String']['input']>;
  rank_gte?: InputMaybe<Scalars['String']['input']>;
  rank_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rank_lt?: InputMaybe<Scalars['String']['input']>;
  rank_lte?: InputMaybe<Scalars['String']['input']>;
  rank_ne?: InputMaybe<Scalars['String']['input']>;
  rank_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_exists?: InputMaybe<Scalars['Boolean']['input']>;
  series_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum ExportSortByInput {
  ARCHIVE_ASC = 'ARCHIVE_ASC',
  ARCHIVE_DESC = 'ARCHIVE_DESC',
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC',
  FILENAME_ASC = 'FILENAME_ASC',
  FILENAME_DESC = 'FILENAME_DESC',
  RANK_ASC = 'RANK_ASC',
  RANK_DESC = 'RANK_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type ExportUpdateInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['String']['input']>;
  archive_unset?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  created_unset?: InputMaybe<Scalars['Boolean']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_unset?: InputMaybe<Scalars['Boolean']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  rank_unset?: InputMaybe<Scalars['Boolean']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_unset?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Facet = {
  procedures?: Maybe<Array<Maybe<FacetProcedure>>>;
  questionCount?: Maybe<Scalars['String']['output']>;
  ranks?: Maybe<Array<Maybe<FacetRank>>>;
  ro?: Maybe<Array<Maybe<FacetRo>>>;
  series?: Maybe<Array<Maybe<FacetSeries>>>;
  tags?: Maybe<Array<Maybe<FacetTag>>>;
  types?: Maybe<Array<Maybe<FacetType>>>;
};

export type FacetProcedure = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type FacetRank = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type FacetRo = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type FacetSeries = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type FacetTag = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type FacetType = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type FacetsFilter = {
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type InsertManyPayload = {
  insertedIds: Array<Maybe<Scalars['ObjectId']['output']>>;
};

export type Mutation = {
  deleteManyCoreCounters?: Maybe<DeleteManyPayload>;
  deleteManyCoreQuestionTypes?: Maybe<DeleteManyPayload>;
  deleteManyExports?: Maybe<DeleteManyPayload>;
  deleteManyQuestions?: Maybe<DeleteManyPayload>;
  deleteManyTrashes?: Maybe<DeleteManyPayload>;
  deleteManyUsers?: Maybe<DeleteManyPayload>;
  deleteOneCoreCounter?: Maybe<CoreCounter>;
  deleteOneCoreQuestionType?: Maybe<CoreQuestionType>;
  deleteOneExport?: Maybe<Export>;
  deleteOneQuestion?: Maybe<Question>;
  deleteOneTrash?: Maybe<Trash>;
  deleteOneUser?: Maybe<User>;
  insertManyCoreCounters?: Maybe<InsertManyPayload>;
  insertManyCoreQuestionTypes?: Maybe<InsertManyPayload>;
  insertManyExports?: Maybe<InsertManyPayload>;
  insertManyQuestions?: Maybe<InsertManyPayload>;
  insertManyTrashes?: Maybe<InsertManyPayload>;
  insertManyUsers?: Maybe<InsertManyPayload>;
  insertOneCoreCounter?: Maybe<CoreCounter>;
  insertOneCoreQuestionType?: Maybe<CoreQuestionType>;
  insertOneExport?: Maybe<Export>;
  insertOneQuestion?: Maybe<Question>;
  insertOneTrash?: Maybe<Trash>;
  insertOneUser?: Maybe<User>;
  replaceOneCoreCounter?: Maybe<CoreCounter>;
  replaceOneCoreQuestionType?: Maybe<CoreQuestionType>;
  replaceOneExport?: Maybe<Export>;
  replaceOneQuestion?: Maybe<Question>;
  replaceOneTrash?: Maybe<Trash>;
  replaceOneUser?: Maybe<User>;
  updateManyCoreCounters?: Maybe<UpdateManyPayload>;
  updateManyCoreQuestionTypes?: Maybe<UpdateManyPayload>;
  updateManyExports?: Maybe<UpdateManyPayload>;
  updateManyQuestions?: Maybe<UpdateManyPayload>;
  updateManyTrashes?: Maybe<UpdateManyPayload>;
  updateManyUsers?: Maybe<UpdateManyPayload>;
  updateOneCoreCounter?: Maybe<CoreCounter>;
  updateOneCoreQuestionType?: Maybe<CoreQuestionType>;
  updateOneExport?: Maybe<Export>;
  updateOneQuestion?: Maybe<Question>;
  updateOneTrash?: Maybe<Trash>;
  updateOneUser?: Maybe<User>;
  upsertOneCoreCounter?: Maybe<CoreCounter>;
  upsertOneCoreQuestionType?: Maybe<CoreQuestionType>;
  upsertOneExport?: Maybe<Export>;
  upsertOneQuestion?: Maybe<Question>;
  upsertOneTrash?: Maybe<Trash>;
  upsertOneUser?: Maybe<User>;
};


export type MutationDeleteManyCoreCountersArgs = {
  query?: InputMaybe<CoreCounterQueryInput>;
};


export type MutationDeleteManyCoreQuestionTypesArgs = {
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
};


export type MutationDeleteManyExportsArgs = {
  query?: InputMaybe<ExportQueryInput>;
};


export type MutationDeleteManyQuestionsArgs = {
  query?: InputMaybe<QuestionQueryInput>;
};


export type MutationDeleteManyTrashesArgs = {
  query?: InputMaybe<TrashQueryInput>;
};


export type MutationDeleteManyUsersArgs = {
  query?: InputMaybe<UserQueryInput>;
};


export type MutationDeleteOneCoreCounterArgs = {
  query: CoreCounterQueryInput;
};


export type MutationDeleteOneCoreQuestionTypeArgs = {
  query: CoreQuestionTypeQueryInput;
};


export type MutationDeleteOneExportArgs = {
  query: ExportQueryInput;
};


export type MutationDeleteOneQuestionArgs = {
  query: QuestionQueryInput;
};


export type MutationDeleteOneTrashArgs = {
  query: TrashQueryInput;
};


export type MutationDeleteOneUserArgs = {
  query: UserQueryInput;
};


export type MutationInsertManyCoreCountersArgs = {
  data: Array<CoreCounterInsertInput>;
};


export type MutationInsertManyCoreQuestionTypesArgs = {
  data: Array<CoreQuestionTypeInsertInput>;
};


export type MutationInsertManyExportsArgs = {
  data: Array<ExportInsertInput>;
};


export type MutationInsertManyQuestionsArgs = {
  data: Array<QuestionInsertInput>;
};


export type MutationInsertManyTrashesArgs = {
  data: Array<TrashInsertInput>;
};


export type MutationInsertManyUsersArgs = {
  data: Array<UserInsertInput>;
};


export type MutationInsertOneCoreCounterArgs = {
  data: CoreCounterInsertInput;
};


export type MutationInsertOneCoreQuestionTypeArgs = {
  data: CoreQuestionTypeInsertInput;
};


export type MutationInsertOneExportArgs = {
  data: ExportInsertInput;
};


export type MutationInsertOneQuestionArgs = {
  data: QuestionInsertInput;
};


export type MutationInsertOneTrashArgs = {
  data: TrashInsertInput;
};


export type MutationInsertOneUserArgs = {
  data: UserInsertInput;
};


export type MutationReplaceOneCoreCounterArgs = {
  data: CoreCounterInsertInput;
  query?: InputMaybe<CoreCounterQueryInput>;
};


export type MutationReplaceOneCoreQuestionTypeArgs = {
  data: CoreQuestionTypeInsertInput;
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
};


export type MutationReplaceOneExportArgs = {
  data: ExportInsertInput;
  query?: InputMaybe<ExportQueryInput>;
};


export type MutationReplaceOneQuestionArgs = {
  data: QuestionInsertInput;
  query?: InputMaybe<QuestionQueryInput>;
};


export type MutationReplaceOneTrashArgs = {
  data: TrashInsertInput;
  query?: InputMaybe<TrashQueryInput>;
};


export type MutationReplaceOneUserArgs = {
  data: UserInsertInput;
  query?: InputMaybe<UserQueryInput>;
};


export type MutationUpdateManyCoreCountersArgs = {
  query?: InputMaybe<CoreCounterQueryInput>;
  set: CoreCounterUpdateInput;
};


export type MutationUpdateManyCoreQuestionTypesArgs = {
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
  set: CoreQuestionTypeUpdateInput;
};


export type MutationUpdateManyExportsArgs = {
  query?: InputMaybe<ExportQueryInput>;
  set: ExportUpdateInput;
};


export type MutationUpdateManyQuestionsArgs = {
  query?: InputMaybe<QuestionQueryInput>;
  set: QuestionUpdateInput;
};


export type MutationUpdateManyTrashesArgs = {
  query?: InputMaybe<TrashQueryInput>;
  set: TrashUpdateInput;
};


export type MutationUpdateManyUsersArgs = {
  query?: InputMaybe<UserQueryInput>;
  set: UserUpdateInput;
};


export type MutationUpdateOneCoreCounterArgs = {
  query?: InputMaybe<CoreCounterQueryInput>;
  set: CoreCounterUpdateInput;
};


export type MutationUpdateOneCoreQuestionTypeArgs = {
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
  set: CoreQuestionTypeUpdateInput;
};


export type MutationUpdateOneExportArgs = {
  query?: InputMaybe<ExportQueryInput>;
  set: ExportUpdateInput;
};


export type MutationUpdateOneQuestionArgs = {
  query?: InputMaybe<QuestionQueryInput>;
  set: QuestionUpdateInput;
};


export type MutationUpdateOneTrashArgs = {
  query?: InputMaybe<TrashQueryInput>;
  set: TrashUpdateInput;
};


export type MutationUpdateOneUserArgs = {
  query?: InputMaybe<UserQueryInput>;
  set: UserUpdateInput;
};


export type MutationUpsertOneCoreCounterArgs = {
  data: CoreCounterInsertInput;
  query?: InputMaybe<CoreCounterQueryInput>;
};


export type MutationUpsertOneCoreQuestionTypeArgs = {
  data: CoreQuestionTypeInsertInput;
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
};


export type MutationUpsertOneExportArgs = {
  data: ExportInsertInput;
  query?: InputMaybe<ExportQueryInput>;
};


export type MutationUpsertOneQuestionArgs = {
  data: QuestionInsertInput;
  query?: InputMaybe<QuestionQueryInput>;
};


export type MutationUpsertOneTrashArgs = {
  data: TrashInsertInput;
  query?: InputMaybe<TrashQueryInput>;
};


export type MutationUpsertOneUserArgs = {
  data: UserInsertInput;
  query?: InputMaybe<UserQueryInput>;
};

export type Query = {
  coreCounter?: Maybe<CoreCounter>;
  coreCounters: Array<Maybe<CoreCounter>>;
  coreQuestionType?: Maybe<CoreQuestionType>;
  coreQuestionTypes: Array<Maybe<CoreQuestionType>>;
  export?: Maybe<Export>;
  exports: Array<Maybe<Export>>;
  question?: Maybe<Question>;
  questionFacets?: Maybe<Facet>;
  questionSearch?: Maybe<SearchResult>;
  questions: Array<Maybe<Question>>;
  trash?: Maybe<Trash>;
  trashes: Array<Maybe<Trash>>;
  user?: Maybe<User>;
  users: Array<Maybe<User>>;
};


export type QueryCoreCounterArgs = {
  query?: InputMaybe<CoreCounterQueryInput>;
};


export type QueryCoreCountersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<CoreCounterQueryInput>;
  sortBy?: InputMaybe<CoreCounterSortByInput>;
};


export type QueryCoreQuestionTypeArgs = {
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
};


export type QueryCoreQuestionTypesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<CoreQuestionTypeQueryInput>;
  sortBy?: InputMaybe<CoreQuestionTypeSortByInput>;
};


export type QueryExportArgs = {
  query?: InputMaybe<ExportQueryInput>;
};


export type QueryExportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ExportQueryInput>;
  sortBy?: InputMaybe<ExportSortByInput>;
};


export type QueryQuestionArgs = {
  query?: InputMaybe<QuestionQueryInput>;
};


export type QueryQuestionFacetsArgs = {
  input?: InputMaybe<FacetsFilter>;
};


export type QueryQuestionSearchArgs = {
  input?: InputMaybe<SearchFilter>;
};


export type QueryQuestionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<QuestionQueryInput>;
  sortBy?: InputMaybe<QuestionSortByInput>;
};


export type QueryTrashArgs = {
  query?: InputMaybe<TrashQueryInput>;
};


export type QueryTrashesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<TrashQueryInput>;
  sortBy?: InputMaybe<TrashSortByInput>;
};


export type QueryUserArgs = {
  query?: InputMaybe<UserQueryInput>;
};


export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<UserQueryInput>;
  sortBy?: InputMaybe<UserSortByInput>;
};

export type Question = {
  _id?: Maybe<Scalars['ObjectId']['output']>;
  answers?: Maybe<Array<Maybe<QuestionAnswer>>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTime']['output']>;
  procedures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionId?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  ro?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type QuestionAnswer = {
  correct?: Maybe<Scalars['Boolean']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type QuestionAnswerInsertInput = {
  correct?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAnswerQueryInput = {
  AND?: InputMaybe<Array<QuestionAnswerQueryInput>>;
  OR?: InputMaybe<Array<QuestionAnswerQueryInput>>;
  correct?: InputMaybe<Scalars['Boolean']['input']>;
  correct_exists?: InputMaybe<Scalars['Boolean']['input']>;
  correct_ne?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feedback_gt?: InputMaybe<Scalars['String']['input']>;
  feedback_gte?: InputMaybe<Scalars['String']['input']>;
  feedback_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedback_lt?: InputMaybe<Scalars['String']['input']>;
  feedback_lte?: InputMaybe<Scalars['String']['input']>;
  feedback_ne?: InputMaybe<Scalars['String']['input']>;
  feedback_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuestionAnswerUpdateInput = {
  correct?: InputMaybe<Scalars['Boolean']['input']>;
  correct_unset?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_unset?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionInsertInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  answers?: InputMaybe<Array<InputMaybe<QuestionAnswerInsertInput>>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionId?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  ro?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionQueryInput = {
  AND?: InputMaybe<Array<QuestionQueryInput>>;
  OR?: InputMaybe<Array<QuestionQueryInput>>;
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  answers?: InputMaybe<Array<InputMaybe<QuestionAnswerQueryInput>>>;
  answers_exists?: InputMaybe<Scalars['Boolean']['input']>;
  answers_in?: InputMaybe<Array<InputMaybe<QuestionAnswerQueryInput>>>;
  answers_nin?: InputMaybe<Array<InputMaybe<QuestionAnswerQueryInput>>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  created_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feedback_gt?: InputMaybe<Scalars['String']['input']>;
  feedback_gte?: InputMaybe<Scalars['String']['input']>;
  feedback_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedback_lt?: InputMaybe<Scalars['String']['input']>;
  feedback_lte?: InputMaybe<Scalars['String']['input']>;
  feedback_ne?: InputMaybe<Scalars['String']['input']>;
  feedback_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  modified_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modified_gt?: InputMaybe<Scalars['DateTime']['input']>;
  modified_gte?: InputMaybe<Scalars['DateTime']['input']>;
  modified_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  modified_lt?: InputMaybe<Scalars['DateTime']['input']>;
  modified_lte?: InputMaybe<Scalars['DateTime']['input']>;
  modified_ne?: InputMaybe<Scalars['DateTime']['input']>;
  modified_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  procedures_exists?: InputMaybe<Scalars['Boolean']['input']>;
  procedures_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  procedures_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionId?: InputMaybe<Scalars['Int']['input']>;
  questionId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  questionId_gt?: InputMaybe<Scalars['Int']['input']>;
  questionId_gte?: InputMaybe<Scalars['Int']['input']>;
  questionId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  questionId_lt?: InputMaybe<Scalars['Int']['input']>;
  questionId_lte?: InputMaybe<Scalars['Int']['input']>;
  questionId_ne?: InputMaybe<Scalars['Int']['input']>;
  questionId_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  rank_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rank_gt?: InputMaybe<Scalars['String']['input']>;
  rank_gte?: InputMaybe<Scalars['String']['input']>;
  rank_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rank_lt?: InputMaybe<Scalars['String']['input']>;
  rank_lte?: InputMaybe<Scalars['String']['input']>;
  rank_ne?: InputMaybe<Scalars['String']['input']>;
  rank_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ro?: InputMaybe<Scalars['String']['input']>;
  ro_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ro_gt?: InputMaybe<Scalars['String']['input']>;
  ro_gte?: InputMaybe<Scalars['String']['input']>;
  ro_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ro_lt?: InputMaybe<Scalars['String']['input']>;
  ro_lte?: InputMaybe<Scalars['String']['input']>;
  ro_ne?: InputMaybe<Scalars['String']['input']>;
  ro_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_exists?: InputMaybe<Scalars['Boolean']['input']>;
  series_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum QuestionSortByInput {
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC',
  FEEDBACK_ASC = 'FEEDBACK_ASC',
  FEEDBACK_DESC = 'FEEDBACK_DESC',
  MODIFIED_ASC = 'MODIFIED_ASC',
  MODIFIED_DESC = 'MODIFIED_DESC',
  QUESTIONID_ASC = 'QUESTIONID_ASC',
  QUESTIONID_DESC = 'QUESTIONID_DESC',
  RANK_ASC = 'RANK_ASC',
  RANK_DESC = 'RANK_DESC',
  RO_ASC = 'RO_ASC',
  RO_DESC = 'RO_DESC',
  TEXT_ASC = 'TEXT_ASC',
  TEXT_DESC = 'TEXT_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type QuestionUpdateInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  answers?: InputMaybe<Array<InputMaybe<QuestionAnswerUpdateInput>>>;
  answers_unset?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  created_unset?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_unset?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  modified_unset?: InputMaybe<Scalars['Boolean']['input']>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  procedures_unset?: InputMaybe<Scalars['Boolean']['input']>;
  questionId?: InputMaybe<Scalars['Int']['input']>;
  questionId_inc?: InputMaybe<Scalars['Int']['input']>;
  questionId_unset?: InputMaybe<Scalars['Boolean']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  rank_unset?: InputMaybe<Scalars['Boolean']['input']>;
  ro?: InputMaybe<Scalars['String']['input']>;
  ro_unset?: InputMaybe<Scalars['Boolean']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_unset?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_unset?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_unset?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchFilter = {
  options?: InputMaybe<SearchFilterOption>;
  perPage?: InputMaybe<Scalars['Float']['input']>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  ro?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skipPage?: InputMaybe<Scalars['Float']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SearchFilterOption = {
  any?: InputMaybe<Scalars['Boolean']['input']>;
  phrase?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['Boolean']['input']>;
  wildcard?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchResult = {
  hits?: Maybe<Array<Maybe<SearchResultHit>>>;
  meta?: Maybe<SearchResultMetum>;
};

export type SearchResultHit = {
  _id?: Maybe<Scalars['ObjectId']['output']>;
  answers?: Maybe<Array<Maybe<SearchResultHitAnswer>>>;
  created?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  modified?: Maybe<Scalars['String']['output']>;
  procedures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionId?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  ro?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SearchResultHitAnswer = {
  correct?: Maybe<Scalars['Boolean']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type SearchResultMetum = {
  count?: Maybe<Scalars['Float']['output']>;
  facets?: Maybe<SearchResultMetumFacet>;
  page?: Maybe<SearchResultMetumPage>;
};

export type SearchResultMetumFacet = {
  procedures?: Maybe<Array<Maybe<SearchResultMetumFacetProcedure>>>;
  ranks?: Maybe<Array<Maybe<SearchResultMetumFacetRank>>>;
  ro?: Maybe<Array<Maybe<SearchResultMetumFacetRo>>>;
  series?: Maybe<Array<Maybe<SearchResultMetumFacetSeries>>>;
  tags?: Maybe<Array<Maybe<SearchResultMetumFacetTag>>>;
  types?: Maybe<Array<Maybe<SearchResultMetumFacetType>>>;
};

export type SearchResultMetumFacetProcedure = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type SearchResultMetumFacetRank = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type SearchResultMetumFacetRo = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type SearchResultMetumFacetSeries = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type SearchResultMetumFacetTag = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type SearchResultMetumFacetType = {
  _id?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

export type SearchResultMetumPage = {
  current?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type Trash = {
  _id?: Maybe<Scalars['ObjectId']['output']>;
  answers?: Maybe<Array<Maybe<TrashAnswer>>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['DateTime']['output']>;
  procedures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionId?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  ro?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TrashAnswer = {
  correct?: Maybe<Scalars['Boolean']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type TrashAnswerInsertInput = {
  correct?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type TrashAnswerQueryInput = {
  AND?: InputMaybe<Array<TrashAnswerQueryInput>>;
  OR?: InputMaybe<Array<TrashAnswerQueryInput>>;
  correct?: InputMaybe<Scalars['Boolean']['input']>;
  correct_exists?: InputMaybe<Scalars['Boolean']['input']>;
  correct_ne?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feedback_gt?: InputMaybe<Scalars['String']['input']>;
  feedback_gte?: InputMaybe<Scalars['String']['input']>;
  feedback_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedback_lt?: InputMaybe<Scalars['String']['input']>;
  feedback_lte?: InputMaybe<Scalars['String']['input']>;
  feedback_ne?: InputMaybe<Scalars['String']['input']>;
  feedback_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TrashAnswerUpdateInput = {
  correct?: InputMaybe<Scalars['Boolean']['input']>;
  correct_unset?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_unset?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TrashInsertInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  answers?: InputMaybe<Array<InputMaybe<TrashAnswerInsertInput>>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['DateTime']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionId?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  ro?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TrashQueryInput = {
  AND?: InputMaybe<Array<TrashQueryInput>>;
  OR?: InputMaybe<Array<TrashQueryInput>>;
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  answers?: InputMaybe<Array<InputMaybe<TrashAnswerQueryInput>>>;
  answers_exists?: InputMaybe<Scalars['Boolean']['input']>;
  answers_in?: InputMaybe<Array<InputMaybe<TrashAnswerQueryInput>>>;
  answers_nin?: InputMaybe<Array<InputMaybe<TrashAnswerQueryInput>>>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  created_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  deleted?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_exists?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_gt?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_gte?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  deleted_lt?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_lte?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_ne?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feedback_gt?: InputMaybe<Scalars['String']['input']>;
  feedback_gte?: InputMaybe<Scalars['String']['input']>;
  feedback_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedback_lt?: InputMaybe<Scalars['String']['input']>;
  feedback_lte?: InputMaybe<Scalars['String']['input']>;
  feedback_ne?: InputMaybe<Scalars['String']['input']>;
  feedback_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  modified_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modified_gt?: InputMaybe<Scalars['DateTime']['input']>;
  modified_gte?: InputMaybe<Scalars['DateTime']['input']>;
  modified_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  modified_lt?: InputMaybe<Scalars['DateTime']['input']>;
  modified_lte?: InputMaybe<Scalars['DateTime']['input']>;
  modified_ne?: InputMaybe<Scalars['DateTime']['input']>;
  modified_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  procedures_exists?: InputMaybe<Scalars['Boolean']['input']>;
  procedures_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  procedures_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionId?: InputMaybe<Scalars['Int']['input']>;
  questionId_exists?: InputMaybe<Scalars['Boolean']['input']>;
  questionId_gt?: InputMaybe<Scalars['Int']['input']>;
  questionId_gte?: InputMaybe<Scalars['Int']['input']>;
  questionId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  questionId_lt?: InputMaybe<Scalars['Int']['input']>;
  questionId_lte?: InputMaybe<Scalars['Int']['input']>;
  questionId_ne?: InputMaybe<Scalars['Int']['input']>;
  questionId_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  rank_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rank_gt?: InputMaybe<Scalars['String']['input']>;
  rank_gte?: InputMaybe<Scalars['String']['input']>;
  rank_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rank_lt?: InputMaybe<Scalars['String']['input']>;
  rank_lte?: InputMaybe<Scalars['String']['input']>;
  rank_ne?: InputMaybe<Scalars['String']['input']>;
  rank_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ro?: InputMaybe<Scalars['String']['input']>;
  ro_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ro_gt?: InputMaybe<Scalars['String']['input']>;
  ro_gte?: InputMaybe<Scalars['String']['input']>;
  ro_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ro_lt?: InputMaybe<Scalars['String']['input']>;
  ro_lte?: InputMaybe<Scalars['String']['input']>;
  ro_ne?: InputMaybe<Scalars['String']['input']>;
  ro_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_exists?: InputMaybe<Scalars['Boolean']['input']>;
  series_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum TrashSortByInput {
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC',
  DELETED_ASC = 'DELETED_ASC',
  DELETED_DESC = 'DELETED_DESC',
  FEEDBACK_ASC = 'FEEDBACK_ASC',
  FEEDBACK_DESC = 'FEEDBACK_DESC',
  MODIFIED_ASC = 'MODIFIED_ASC',
  MODIFIED_DESC = 'MODIFIED_DESC',
  QUESTIONID_ASC = 'QUESTIONID_ASC',
  QUESTIONID_DESC = 'QUESTIONID_DESC',
  RANK_ASC = 'RANK_ASC',
  RANK_DESC = 'RANK_DESC',
  RO_ASC = 'RO_ASC',
  RO_DESC = 'RO_DESC',
  TEXT_ASC = 'TEXT_ASC',
  TEXT_DESC = 'TEXT_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type TrashUpdateInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  answers?: InputMaybe<Array<InputMaybe<TrashAnswerUpdateInput>>>;
  answers_unset?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  created_unset?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_unset?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_unset?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  modified_unset?: InputMaybe<Scalars['Boolean']['input']>;
  procedures?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  procedures_unset?: InputMaybe<Scalars['Boolean']['input']>;
  questionId?: InputMaybe<Scalars['Int']['input']>;
  questionId_inc?: InputMaybe<Scalars['Int']['input']>;
  questionId_unset?: InputMaybe<Scalars['Boolean']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  rank_unset?: InputMaybe<Scalars['Boolean']['input']>;
  ro?: InputMaybe<Scalars['String']['input']>;
  ro_unset?: InputMaybe<Scalars['Boolean']['input']>;
  series?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  series_unset?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_unset?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_unset?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateManyPayload = {
  matchedCount: Scalars['Int']['output'];
  modifiedCount: Scalars['Int']['output'];
};

export type User = {
  _id?: Maybe<Scalars['ObjectId']['output']>;
  admin?: Maybe<Scalars['Boolean']['output']>;
  bookmarks?: Maybe<Array<Maybe<UserBookmark>>>;
  email?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserBookmark = {
  id?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type UserBookmarkInsertInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UserBookmarkQueryInput = {
  AND?: InputMaybe<Array<UserBookmarkQueryInput>>;
  OR?: InputMaybe<Array<UserBookmarkQueryInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  id_ne?: InputMaybe<Scalars['String']['input']>;
  id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserBookmarkUpdateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_unset?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserInsertInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  bookmarks?: InputMaybe<Array<InputMaybe<UserBookmarkInsertInput>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserQueryInput = {
  AND?: InputMaybe<Array<UserQueryInput>>;
  OR?: InputMaybe<Array<UserQueryInput>>;
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  admin_exists?: InputMaybe<Scalars['Boolean']['input']>;
  admin_ne?: InputMaybe<Scalars['Boolean']['input']>;
  bookmarks?: InputMaybe<Array<InputMaybe<UserBookmarkQueryInput>>>;
  bookmarks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bookmarks_in?: InputMaybe<Array<InputMaybe<UserBookmarkQueryInput>>>;
  bookmarks_nin?: InputMaybe<Array<InputMaybe<UserBookmarkQueryInput>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_exists?: InputMaybe<Scalars['Boolean']['input']>;
  email_gt?: InputMaybe<Scalars['String']['input']>;
  email_gte?: InputMaybe<Scalars['String']['input']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_lt?: InputMaybe<Scalars['String']['input']>;
  email_lte?: InputMaybe<Scalars['String']['input']>;
  email_ne?: InputMaybe<Scalars['String']['input']>;
  email_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lastLogin_gt?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_gte?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lastLogin_lt?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_lte?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_ne?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_ne?: InputMaybe<Scalars['String']['input']>;
  name_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  roles_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roles_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum UserSortByInput {
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  LASTLOGIN_ASC = 'LASTLOGIN_ASC',
  LASTLOGIN_DESC = 'LASTLOGIN_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type UserUpdateInput = {
  _id?: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset?: InputMaybe<Scalars['Boolean']['input']>;
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  admin_unset?: InputMaybe<Scalars['Boolean']['input']>;
  bookmarks?: InputMaybe<Array<InputMaybe<UserBookmarkUpdateInput>>>;
  bookmarks_unset?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_unset?: InputMaybe<Scalars['Boolean']['input']>;
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  lastLogin_unset?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_unset?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roles_unset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetQuestionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionTypesQuery = { coreQuestionTypes: Array<{ _id: string, colorName: string, hasAnswerFeedback: boolean, hasGeneralFeedback: boolean, hasImages: boolean, hasMultipleCorrect: boolean, iconName: string, isTrueFalse: boolean, label: string } | null> };

export type GetQuestionTypeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetQuestionTypeQuery = { coreQuestionType?: { _id: string, colorName: string, hasAnswerFeedback: boolean, hasGeneralFeedback: boolean, hasImages: boolean, hasMultipleCorrect: boolean, iconName: string, isTrueFalse: boolean, label: string } | null };

export type GetQuestionFacetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionFacetsQuery = { questionFacets?: { types?: Array<{ _id?: string | null } | null> | null, series?: Array<{ _id?: string | null } | null> | null, procedures?: Array<{ _id?: string | null } | null> | null, tags?: Array<{ _id?: string | null } | null> | null, ro?: Array<{ _id?: string | null } | null> | null } | null };

export type GetQuestionFacetsAndCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionFacetsAndCountsQuery = { questionFacets?: { types?: Array<{ _id?: string | null, count?: number | null } | null> | null, ranks?: Array<{ _id?: string | null, count?: number | null } | null> | null, series?: Array<{ _id?: string | null, count?: number | null } | null> | null, procedures?: Array<{ _id?: string | null, count?: number | null } | null> | null, tags?: Array<{ _id?: string | null, count?: number | null } | null> | null, ro?: Array<{ _id?: string | null, count?: number | null } | null> | null } | null };

export type GetQuestionFacetsFilteredQueryVariables = Exact<{
  filters?: InputMaybe<FacetsFilter>;
}>;


export type GetQuestionFacetsFilteredQuery = { questionFacets?: { questionCount?: string | null, types?: Array<{ _id?: string | null, count?: number | null } | null> | null, ranks?: Array<{ _id?: string | null, count?: number | null } | null> | null, series?: Array<{ _id?: string | null, count?: number | null } | null> | null, procedures?: Array<{ _id?: string | null, count?: number | null } | null> | null, tags?: Array<{ _id?: string | null, count?: number | null } | null> | null, ro?: Array<{ _id?: string | null, count?: number | null } | null> | null } | null };

export type GetQuestionByIdQueryVariables = Exact<{
  id: Scalars['ObjectId']['input'];
}>;


export type GetQuestionByIdQuery = { question?: { _id?: any | null, created?: any | null, modified?: any | null, procedures?: Array<string | null> | null, rank?: string | null, series?: Array<string | null> | null, text?: string | null, type?: string | null, tags?: Array<string | null> | null, feedback?: string | null, ro?: string | null, questionId?: number | null, answers?: Array<{ correct?: boolean | null, feedback?: string | null, text?: string | null } | null> | null } | null };

export type GetQuestionsBySeriesQueryVariables = Exact<{
  series: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetQuestionsBySeriesQuery = { questions: Array<{ _id?: any | null, created?: any | null, modified?: any | null, procedures?: Array<string | null> | null, rank?: string | null, series?: Array<string | null> | null, text?: string | null, type?: string | null, tags?: Array<string | null> | null, feedback?: string | null, ro?: string | null, questionId?: number | null, answers?: Array<{ correct?: boolean | null, feedback?: string | null, text?: string | null } | null> | null } | null> };

export type GetQuestionsByIdQueryVariables = Exact<{
  ids: Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input'];
}>;


export type GetQuestionsByIdQuery = { questions: Array<{ _id?: any | null, created?: any | null, modified?: any | null, procedures?: Array<string | null> | null, rank?: string | null, series?: Array<string | null> | null, text?: string | null, type?: string | null, tags?: Array<string | null> | null, feedback?: string | null, ro?: string | null, questionId?: number | null, answers?: Array<{ correct?: boolean | null, feedback?: string | null, text?: string | null } | null> | null } | null> };

export type SearchQuestionsQueryVariables = Exact<{
  query: SearchFilter;
}>;


export type SearchQuestionsQuery = { questionSearch?: { hits?: Array<{ _id?: any | null, feedback?: string | null, text?: string | null, type?: string | null, series?: Array<string | null> | null, procedures?: Array<string | null> | null, tags?: Array<string | null> | null, rank?: string | null, ro?: string | null, questionId?: number | null, highlights?: Array<string | null> | null, answers?: Array<{ text?: string | null, feedback?: string | null } | null> | null } | null> | null, meta?: { count?: number | null, facets?: { ro?: Array<{ _id?: string | null, count?: number | null } | null> | null, procedures?: Array<{ _id?: string | null, count?: number | null } | null> | null, series?: Array<{ _id?: string | null, count?: number | null } | null> | null, ranks?: Array<{ _id?: string | null, count?: number | null } | null> | null, tags?: Array<{ _id?: string | null, count?: number | null } | null> | null, types?: Array<{ _id?: string | null, count?: number | null } | null> | null } | null, page?: { total?: number | null, current?: number | null } | null } | null } | null };

export type SearchQuestionsReturningIdsQueryVariables = Exact<{
  query: SearchFilter;
}>;


export type SearchQuestionsReturningIdsQuery = { questionSearch?: { hits?: Array<{ _id?: any | null } | null> | null } | null };

export type AddQuestionMutationVariables = Exact<{
  question: QuestionInsertInput;
}>;


export type AddQuestionMutation = { addedQuestion?: { _id?: any | null } | null };

export type AddQuestionsMutationVariables = Exact<{
  questions: Array<QuestionInsertInput> | QuestionInsertInput;
}>;


export type AddQuestionsMutation = { addedQuestions?: { insertedIds: Array<any | null> } | null };

export type UpdateQuestionMutationVariables = Exact<{
  questionId: Scalars['ObjectId']['input'];
  updates: QuestionUpdateInput;
}>;


export type UpdateQuestionMutation = { updatedQuestion?: { _id?: any | null, created?: any | null, modified?: any | null, procedures?: Array<string | null> | null, rank?: string | null, series?: Array<string | null> | null, text?: string | null, type?: string | null, feedback?: string | null, ro?: string | null, questionId?: number | null, answers?: Array<{ correct?: boolean | null, feedback?: string | null, text?: string | null } | null> | null } | null };

export type UpdateManyQuestionsMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ObjectId']['input']>> | InputMaybe<Scalars['ObjectId']['input']>;
  set: QuestionUpdateInput;
}>;


export type UpdateManyQuestionsMutation = { updateManyQuestions?: { matchedCount: number, modifiedCount: number } | null };

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['ObjectId']['input'];
}>;


export type DeleteQuestionMutation = { deleteOneQuestion?: { _id?: any | null } | null };

export type DeleteQuestionsMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ObjectId']['input']>> | InputMaybe<Scalars['ObjectId']['input']>;
}>;


export type DeleteQuestionsMutation = { deleteManyQuestions?: { deletedCount: number } | null };

export type AddManyTrashesMutationVariables = Exact<{
  trashes: Array<TrashInsertInput> | TrashInsertInput;
}>;


export type AddManyTrashesMutation = { addedTrashes?: { insertedIds: Array<any | null> } | null };

export type GetTrashesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrashesQuery = { trashes: Array<{ _id?: any | null, text?: string | null, type?: string | null, deleted?: any | null } | null> };

export type GetTrashByIdQueryVariables = Exact<{
  id: Scalars['ObjectId']['input'];
}>;


export type GetTrashByIdQuery = { trash?: { _id?: any | null, created?: any | null, modified?: any | null, procedures?: Array<string | null> | null, rank?: string | null, series?: Array<string | null> | null, text?: string | null, type?: string | null, tags?: Array<string | null> | null, feedback?: string | null, ro?: string | null, questionId?: number | null, answers?: Array<{ correct?: boolean | null, feedback?: string | null, text?: string | null } | null> | null } | null };

export type DeleteTrashMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ObjectId']['input']>> | InputMaybe<Scalars['ObjectId']['input']>;
}>;


export type DeleteTrashMutation = { deleteManyTrashes?: { deletedCount: number } | null };

export type AddUserMutationVariables = Exact<{
  user: UserInsertInput;
}>;


export type AddUserMutation = { addedUser?: { _id?: any | null } | null };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ObjectId']['input'];
  updates: UserUpdateInput;
}>;


export type UpdateUserMutation = { updatedUser?: { _id?: any | null } | null };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { users: Array<{ _id?: any | null, email?: string | null, name?: string | null, lastLogin?: any | null } | null> };


export const GetQuestionTypesDocument = gql`
    query GetQuestionTypes {
  coreQuestionTypes(sortBy: LABEL_ASC) {
    _id
    colorName
    hasAnswerFeedback
    hasGeneralFeedback
    hasImages
    hasMultipleCorrect
    iconName
    isTrueFalse
    label
  }
}
    `;

/**
 * __useGetQuestionTypesQuery__
 *
 * To run a query within a React component, call `useGetQuestionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>(GetQuestionTypesDocument, options);
      }
export function useGetQuestionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>(GetQuestionTypesDocument, options);
        }
export type GetQuestionTypesQueryHookResult = ReturnType<typeof useGetQuestionTypesQuery>;
export type GetQuestionTypesLazyQueryHookResult = ReturnType<typeof useGetQuestionTypesLazyQuery>;
export type GetQuestionTypesQueryResult = Apollo.QueryResult<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>;
export const GetQuestionTypeDocument = gql`
    query GetQuestionType($id: String!) {
  coreQuestionType(query: {_id: $id}) {
    _id
    colorName
    hasAnswerFeedback
    hasGeneralFeedback
    hasImages
    hasMultipleCorrect
    iconName
    isTrueFalse
    label
  }
}
    `;

/**
 * __useGetQuestionTypeQuery__
 *
 * To run a query within a React component, call `useGetQuestionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionTypeQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>(GetQuestionTypeDocument, options);
      }
export function useGetQuestionTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>(GetQuestionTypeDocument, options);
        }
export type GetQuestionTypeQueryHookResult = ReturnType<typeof useGetQuestionTypeQuery>;
export type GetQuestionTypeLazyQueryHookResult = ReturnType<typeof useGetQuestionTypeLazyQuery>;
export type GetQuestionTypeQueryResult = Apollo.QueryResult<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>;
export const GetQuestionFacetsDocument = gql`
    query GetQuestionFacets {
  questionFacets {
    types {
      _id
    }
    series {
      _id
    }
    procedures {
      _id
    }
    tags {
      _id
    }
    ro {
      _id
    }
  }
}
    `;

/**
 * __useGetQuestionFacetsQuery__
 *
 * To run a query within a React component, call `useGetQuestionFacetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionFacetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionFacetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionFacetsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionFacetsQuery, GetQuestionFacetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionFacetsQuery, GetQuestionFacetsQueryVariables>(GetQuestionFacetsDocument, options);
      }
export function useGetQuestionFacetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionFacetsQuery, GetQuestionFacetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionFacetsQuery, GetQuestionFacetsQueryVariables>(GetQuestionFacetsDocument, options);
        }
export type GetQuestionFacetsQueryHookResult = ReturnType<typeof useGetQuestionFacetsQuery>;
export type GetQuestionFacetsLazyQueryHookResult = ReturnType<typeof useGetQuestionFacetsLazyQuery>;
export type GetQuestionFacetsQueryResult = Apollo.QueryResult<GetQuestionFacetsQuery, GetQuestionFacetsQueryVariables>;
export const GetQuestionFacetsAndCountsDocument = gql`
    query GetQuestionFacetsAndCounts {
  questionFacets {
    types {
      _id
      count
    }
    ranks {
      _id
      count
    }
    series {
      _id
      count
    }
    procedures {
      _id
      count
    }
    tags {
      _id
      count
    }
    ro {
      _id
      count
    }
  }
}
    `;

/**
 * __useGetQuestionFacetsAndCountsQuery__
 *
 * To run a query within a React component, call `useGetQuestionFacetsAndCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionFacetsAndCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionFacetsAndCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionFacetsAndCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionFacetsAndCountsQuery, GetQuestionFacetsAndCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionFacetsAndCountsQuery, GetQuestionFacetsAndCountsQueryVariables>(GetQuestionFacetsAndCountsDocument, options);
      }
export function useGetQuestionFacetsAndCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionFacetsAndCountsQuery, GetQuestionFacetsAndCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionFacetsAndCountsQuery, GetQuestionFacetsAndCountsQueryVariables>(GetQuestionFacetsAndCountsDocument, options);
        }
export type GetQuestionFacetsAndCountsQueryHookResult = ReturnType<typeof useGetQuestionFacetsAndCountsQuery>;
export type GetQuestionFacetsAndCountsLazyQueryHookResult = ReturnType<typeof useGetQuestionFacetsAndCountsLazyQuery>;
export type GetQuestionFacetsAndCountsQueryResult = Apollo.QueryResult<GetQuestionFacetsAndCountsQuery, GetQuestionFacetsAndCountsQueryVariables>;
export const GetQuestionFacetsFilteredDocument = gql`
    query GetQuestionFacetsFiltered($filters: FacetsFilter) {
  questionFacets(input: $filters) {
    types {
      _id
      count
    }
    ranks {
      _id
      count
    }
    series {
      _id
      count
    }
    procedures {
      _id
      count
    }
    tags {
      _id
      count
    }
    ro {
      _id
      count
    }
    questionCount
  }
}
    `;

/**
 * __useGetQuestionFacetsFilteredQuery__
 *
 * To run a query within a React component, call `useGetQuestionFacetsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionFacetsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionFacetsFilteredQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetQuestionFacetsFilteredQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionFacetsFilteredQuery, GetQuestionFacetsFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionFacetsFilteredQuery, GetQuestionFacetsFilteredQueryVariables>(GetQuestionFacetsFilteredDocument, options);
      }
export function useGetQuestionFacetsFilteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionFacetsFilteredQuery, GetQuestionFacetsFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionFacetsFilteredQuery, GetQuestionFacetsFilteredQueryVariables>(GetQuestionFacetsFilteredDocument, options);
        }
export type GetQuestionFacetsFilteredQueryHookResult = ReturnType<typeof useGetQuestionFacetsFilteredQuery>;
export type GetQuestionFacetsFilteredLazyQueryHookResult = ReturnType<typeof useGetQuestionFacetsFilteredLazyQuery>;
export type GetQuestionFacetsFilteredQueryResult = Apollo.QueryResult<GetQuestionFacetsFilteredQuery, GetQuestionFacetsFilteredQueryVariables>;
export const GetQuestionByIdDocument = gql`
    query GetQuestionById($id: ObjectId!) {
  question(query: {_id: $id}) {
    _id
    answers {
      correct
      feedback
      text
    }
    created
    modified
    procedures
    rank
    series
    text
    type
    tags
    feedback
    ro
    questionId
  }
}
    `;

/**
 * __useGetQuestionByIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionByIdQuery, GetQuestionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionByIdQuery, GetQuestionByIdQueryVariables>(GetQuestionByIdDocument, options);
      }
export function useGetQuestionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionByIdQuery, GetQuestionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionByIdQuery, GetQuestionByIdQueryVariables>(GetQuestionByIdDocument, options);
        }
export type GetQuestionByIdQueryHookResult = ReturnType<typeof useGetQuestionByIdQuery>;
export type GetQuestionByIdLazyQueryHookResult = ReturnType<typeof useGetQuestionByIdLazyQuery>;
export type GetQuestionByIdQueryResult = Apollo.QueryResult<GetQuestionByIdQuery, GetQuestionByIdQueryVariables>;
export const GetQuestionsBySeriesDocument = gql`
    query GetQuestionsBySeries($series: [String]!, $type: String) {
  questions(query: {series_in: $series, type: $type}, limit: 1000) {
    _id
    answers {
      correct
      feedback
      text
    }
    created
    modified
    procedures
    rank
    series
    text
    type
    tags
    feedback
    ro
    questionId
  }
}
    `;

/**
 * __useGetQuestionsBySeriesQuery__
 *
 * To run a query within a React component, call `useGetQuestionsBySeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsBySeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsBySeriesQuery({
 *   variables: {
 *      series: // value for 'series'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetQuestionsBySeriesQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionsBySeriesQuery, GetQuestionsBySeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsBySeriesQuery, GetQuestionsBySeriesQueryVariables>(GetQuestionsBySeriesDocument, options);
      }
export function useGetQuestionsBySeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsBySeriesQuery, GetQuestionsBySeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsBySeriesQuery, GetQuestionsBySeriesQueryVariables>(GetQuestionsBySeriesDocument, options);
        }
export type GetQuestionsBySeriesQueryHookResult = ReturnType<typeof useGetQuestionsBySeriesQuery>;
export type GetQuestionsBySeriesLazyQueryHookResult = ReturnType<typeof useGetQuestionsBySeriesLazyQuery>;
export type GetQuestionsBySeriesQueryResult = Apollo.QueryResult<GetQuestionsBySeriesQuery, GetQuestionsBySeriesQueryVariables>;
export const GetQuestionsByIdDocument = gql`
    query GetQuestionsById($ids: [ObjectId!]!) {
  questions(query: {_id_in: $ids}) {
    _id
    answers {
      correct
      feedback
      text
    }
    created
    modified
    procedures
    rank
    series
    text
    type
    tags
    feedback
    ro
    questionId
  }
}
    `;

/**
 * __useGetQuestionsByIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetQuestionsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionsByIdQuery, GetQuestionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsByIdQuery, GetQuestionsByIdQueryVariables>(GetQuestionsByIdDocument, options);
      }
export function useGetQuestionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsByIdQuery, GetQuestionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsByIdQuery, GetQuestionsByIdQueryVariables>(GetQuestionsByIdDocument, options);
        }
export type GetQuestionsByIdQueryHookResult = ReturnType<typeof useGetQuestionsByIdQuery>;
export type GetQuestionsByIdLazyQueryHookResult = ReturnType<typeof useGetQuestionsByIdLazyQuery>;
export type GetQuestionsByIdQueryResult = Apollo.QueryResult<GetQuestionsByIdQuery, GetQuestionsByIdQueryVariables>;
export const SearchQuestionsDocument = gql`
    query SearchQuestions($query: SearchFilter!) {
  questionSearch(input: $query) {
    hits {
      _id
      answers {
        text
        feedback
      }
      feedback
      text
      type
      series
      procedures
      tags
      rank
      ro
      questionId
      highlights
    }
    meta {
      count
      facets {
        ro {
          _id
          count
        }
        procedures {
          _id
          count
        }
        series {
          _id
          count
        }
        ranks {
          _id
          count
        }
        tags {
          _id
          count
        }
        types {
          _id
          count
        }
      }
      page {
        total
        current
      }
    }
  }
}
    `;

/**
 * __useSearchQuestionsQuery__
 *
 * To run a query within a React component, call `useSearchQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuestionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchQuestionsQuery(baseOptions: Apollo.QueryHookOptions<SearchQuestionsQuery, SearchQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuestionsQuery, SearchQuestionsQueryVariables>(SearchQuestionsDocument, options);
      }
export function useSearchQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuestionsQuery, SearchQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuestionsQuery, SearchQuestionsQueryVariables>(SearchQuestionsDocument, options);
        }
export type SearchQuestionsQueryHookResult = ReturnType<typeof useSearchQuestionsQuery>;
export type SearchQuestionsLazyQueryHookResult = ReturnType<typeof useSearchQuestionsLazyQuery>;
export type SearchQuestionsQueryResult = Apollo.QueryResult<SearchQuestionsQuery, SearchQuestionsQueryVariables>;
export const SearchQuestionsReturningIdsDocument = gql`
    query SearchQuestionsReturningIds($query: SearchFilter!) {
  questionSearch(input: $query) {
    hits {
      _id
    }
  }
}
    `;

/**
 * __useSearchQuestionsReturningIdsQuery__
 *
 * To run a query within a React component, call `useSearchQuestionsReturningIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuestionsReturningIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuestionsReturningIdsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchQuestionsReturningIdsQuery(baseOptions: Apollo.QueryHookOptions<SearchQuestionsReturningIdsQuery, SearchQuestionsReturningIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuestionsReturningIdsQuery, SearchQuestionsReturningIdsQueryVariables>(SearchQuestionsReturningIdsDocument, options);
      }
export function useSearchQuestionsReturningIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuestionsReturningIdsQuery, SearchQuestionsReturningIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuestionsReturningIdsQuery, SearchQuestionsReturningIdsQueryVariables>(SearchQuestionsReturningIdsDocument, options);
        }
export type SearchQuestionsReturningIdsQueryHookResult = ReturnType<typeof useSearchQuestionsReturningIdsQuery>;
export type SearchQuestionsReturningIdsLazyQueryHookResult = ReturnType<typeof useSearchQuestionsReturningIdsLazyQuery>;
export type SearchQuestionsReturningIdsQueryResult = Apollo.QueryResult<SearchQuestionsReturningIdsQuery, SearchQuestionsReturningIdsQueryVariables>;
export const AddQuestionDocument = gql`
    mutation AddQuestion($question: QuestionInsertInput!) {
  addedQuestion: insertOneQuestion(data: $question) {
    _id
  }
}
    `;
export type AddQuestionMutationFn = Apollo.MutationFunction<AddQuestionMutation, AddQuestionMutationVariables>;

/**
 * __useAddQuestionMutation__
 *
 * To run a mutation, you first call `useAddQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionMutation, { data, loading, error }] = useAddQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionMutation, AddQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionMutation, AddQuestionMutationVariables>(AddQuestionDocument, options);
      }
export type AddQuestionMutationHookResult = ReturnType<typeof useAddQuestionMutation>;
export type AddQuestionMutationResult = Apollo.MutationResult<AddQuestionMutation>;
export type AddQuestionMutationOptions = Apollo.BaseMutationOptions<AddQuestionMutation, AddQuestionMutationVariables>;
export const AddQuestionsDocument = gql`
    mutation AddQuestions($questions: [QuestionInsertInput!]!) {
  addedQuestions: insertManyQuestions(data: $questions) {
    insertedIds
  }
}
    `;
export type AddQuestionsMutationFn = Apollo.MutationFunction<AddQuestionsMutation, AddQuestionsMutationVariables>;

/**
 * __useAddQuestionsMutation__
 *
 * To run a mutation, you first call `useAddQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionsMutation, { data, loading, error }] = useAddQuestionsMutation({
 *   variables: {
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useAddQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionsMutation, AddQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionsMutation, AddQuestionsMutationVariables>(AddQuestionsDocument, options);
      }
export type AddQuestionsMutationHookResult = ReturnType<typeof useAddQuestionsMutation>;
export type AddQuestionsMutationResult = Apollo.MutationResult<AddQuestionsMutation>;
export type AddQuestionsMutationOptions = Apollo.BaseMutationOptions<AddQuestionsMutation, AddQuestionsMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($questionId: ObjectId!, $updates: QuestionUpdateInput!) {
  updatedQuestion: updateOneQuestion(query: {_id: $questionId}, set: $updates) {
    _id
    answers {
      correct
      feedback
      text
    }
    created
    modified
    procedures
    rank
    series
    text
    type
    feedback
    ro
    questionId
  }
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const UpdateManyQuestionsDocument = gql`
    mutation UpdateManyQuestions($ids: [ObjectId]!, $set: QuestionUpdateInput!) {
  updateManyQuestions: updateManyQuestions(query: {_id_in: $ids}, set: $set) {
    matchedCount
    modifiedCount
  }
}
    `;
export type UpdateManyQuestionsMutationFn = Apollo.MutationFunction<UpdateManyQuestionsMutation, UpdateManyQuestionsMutationVariables>;

/**
 * __useUpdateManyQuestionsMutation__
 *
 * To run a mutation, you first call `useUpdateManyQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyQuestionsMutation, { data, loading, error }] = useUpdateManyQuestionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateManyQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyQuestionsMutation, UpdateManyQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyQuestionsMutation, UpdateManyQuestionsMutationVariables>(UpdateManyQuestionsDocument, options);
      }
export type UpdateManyQuestionsMutationHookResult = ReturnType<typeof useUpdateManyQuestionsMutation>;
export type UpdateManyQuestionsMutationResult = Apollo.MutationResult<UpdateManyQuestionsMutation>;
export type UpdateManyQuestionsMutationOptions = Apollo.BaseMutationOptions<UpdateManyQuestionsMutation, UpdateManyQuestionsMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($id: ObjectId!) {
  deleteOneQuestion(query: {_id: $id}) {
    _id
  }
}
    `;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const DeleteQuestionsDocument = gql`
    mutation deleteQuestions($ids: [ObjectId]!) {
  deleteManyQuestions(query: {_id_in: $ids}) {
    deletedCount
  }
}
    `;
export type DeleteQuestionsMutationFn = Apollo.MutationFunction<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>;

/**
 * __useDeleteQuestionsMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionsMutation, { data, loading, error }] = useDeleteQuestionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>(DeleteQuestionsDocument, options);
      }
export type DeleteQuestionsMutationHookResult = ReturnType<typeof useDeleteQuestionsMutation>;
export type DeleteQuestionsMutationResult = Apollo.MutationResult<DeleteQuestionsMutation>;
export type DeleteQuestionsMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>;
export const AddManyTrashesDocument = gql`
    mutation AddManyTrashes($trashes: [TrashInsertInput!]!) {
  addedTrashes: insertManyTrashes(data: $trashes) {
    insertedIds
  }
}
    `;
export type AddManyTrashesMutationFn = Apollo.MutationFunction<AddManyTrashesMutation, AddManyTrashesMutationVariables>;

/**
 * __useAddManyTrashesMutation__
 *
 * To run a mutation, you first call `useAddManyTrashesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManyTrashesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManyTrashesMutation, { data, loading, error }] = useAddManyTrashesMutation({
 *   variables: {
 *      trashes: // value for 'trashes'
 *   },
 * });
 */
export function useAddManyTrashesMutation(baseOptions?: Apollo.MutationHookOptions<AddManyTrashesMutation, AddManyTrashesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManyTrashesMutation, AddManyTrashesMutationVariables>(AddManyTrashesDocument, options);
      }
export type AddManyTrashesMutationHookResult = ReturnType<typeof useAddManyTrashesMutation>;
export type AddManyTrashesMutationResult = Apollo.MutationResult<AddManyTrashesMutation>;
export type AddManyTrashesMutationOptions = Apollo.BaseMutationOptions<AddManyTrashesMutation, AddManyTrashesMutationVariables>;
export const GetTrashesDocument = gql`
    query GetTrashes {
  trashes(sortBy: DELETED_DESC) {
    _id
    text
    type
    deleted
  }
}
    `;

/**
 * __useGetTrashesQuery__
 *
 * To run a query within a React component, call `useGetTrashesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrashesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrashesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrashesQuery(baseOptions?: Apollo.QueryHookOptions<GetTrashesQuery, GetTrashesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrashesQuery, GetTrashesQueryVariables>(GetTrashesDocument, options);
      }
export function useGetTrashesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrashesQuery, GetTrashesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrashesQuery, GetTrashesQueryVariables>(GetTrashesDocument, options);
        }
export type GetTrashesQueryHookResult = ReturnType<typeof useGetTrashesQuery>;
export type GetTrashesLazyQueryHookResult = ReturnType<typeof useGetTrashesLazyQuery>;
export type GetTrashesQueryResult = Apollo.QueryResult<GetTrashesQuery, GetTrashesQueryVariables>;
export const GetTrashByIdDocument = gql`
    query GetTrashById($id: ObjectId!) {
  trash(query: {_id: $id}) {
    _id
    answers {
      correct
      feedback
      text
    }
    created
    modified
    procedures
    rank
    series
    text
    type
    tags
    feedback
    ro
    questionId
  }
}
    `;

/**
 * __useGetTrashByIdQuery__
 *
 * To run a query within a React component, call `useGetTrashByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrashByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrashByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTrashByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTrashByIdQuery, GetTrashByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrashByIdQuery, GetTrashByIdQueryVariables>(GetTrashByIdDocument, options);
      }
export function useGetTrashByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrashByIdQuery, GetTrashByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrashByIdQuery, GetTrashByIdQueryVariables>(GetTrashByIdDocument, options);
        }
export type GetTrashByIdQueryHookResult = ReturnType<typeof useGetTrashByIdQuery>;
export type GetTrashByIdLazyQueryHookResult = ReturnType<typeof useGetTrashByIdLazyQuery>;
export type GetTrashByIdQueryResult = Apollo.QueryResult<GetTrashByIdQuery, GetTrashByIdQueryVariables>;
export const DeleteTrashDocument = gql`
    mutation DeleteTrash($ids: [ObjectId]!) {
  deleteManyTrashes(query: {_id_in: $ids}) {
    deletedCount
  }
}
    `;
export type DeleteTrashMutationFn = Apollo.MutationFunction<DeleteTrashMutation, DeleteTrashMutationVariables>;

/**
 * __useDeleteTrashMutation__
 *
 * To run a mutation, you first call `useDeleteTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrashMutation, { data, loading, error }] = useDeleteTrashMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTrashMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrashMutation, DeleteTrashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrashMutation, DeleteTrashMutationVariables>(DeleteTrashDocument, options);
      }
export type DeleteTrashMutationHookResult = ReturnType<typeof useDeleteTrashMutation>;
export type DeleteTrashMutationResult = Apollo.MutationResult<DeleteTrashMutation>;
export type DeleteTrashMutationOptions = Apollo.BaseMutationOptions<DeleteTrashMutation, DeleteTrashMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($user: UserInsertInput!) {
  addedUser: insertOneUser(data: $user) {
    _id
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: ObjectId!, $updates: UserUpdateInput!) {
  updatedUser: updateOneUser(query: {_id: $userId}, set: $updates) {
    _id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    _id
    email
    name
    lastLogin
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;