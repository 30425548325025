import { hookstate, useHookstate } from '@hookstate/core';
import { AlertColor } from '@mui/material';
import { remove } from 'lodash';
import { v4 as uuid } from 'uuid';

interface Notification {
  message: string;
  severity?: AlertColor;
}
export interface NotificationItem extends Notification {
  id: string;
}
interface NotificationState {
  notifications: NotificationItem[];
}

const notificationState = hookstate<NotificationState>({
  notifications: [],
});

const useNotifications = () => {
  const state = useHookstate(notificationState);

  return {
    get notifications() {
      return state.notifications.get();
    },
    addNotification(notification: Notification) {
      state.notifications.set((prevState) => [
        ...prevState,
        {
          id: uuid(),
          ...notification,
        },
      ]);
    },
    removeNotification(id: string) {
      state.notifications.set((prevState) =>
        remove(prevState, (notification) => notification.id === id),
      );
    },
  };
};

export default useNotifications;
