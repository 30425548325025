import { debounce } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { stripHtml } from 'string-strip-html';

import { useMaterialUIController } from 'providers/MaterialUIProvider';

import MDEditorRoot from 'components/MDB/MDEditor/MDEditorRoot';
import MDInput from 'components/MDB/MDInput';

import MDBox from '../MDBox';

const icons = Quill.import('ui/icons');
icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

interface Props {
  value: string | undefined;
  hasImage?: boolean;
  hideToolbar?: boolean;
  onChange: any;
  readOnly?: boolean;
}

const MDEditor: FC<Props> = ({
  value,
  hasImage,
  readOnly = false,
  onChange,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    const Inline = Quill.import('blots/inline');
    class MarkBlot extends Inline {}
    MarkBlot.blotName = 'mark';
    MarkBlot.tagName = 'mark';
    Quill.register(MarkBlot, true);
  }, []);

  const undoChange = () => {
    const quill = quillRef?.current?.getEditor();
    // @ts-ignore
    return quill ? quill.history.undo() : undefined;
  };
  const redoChange = () => {
    const quill = quillRef?.current?.getEditor();
    // @ts-ignore
    return quill.history.redo();
  };

  const quillModule = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [
            { list: 'ordered' },
            {
              list: 'bullet',
            },
          ],
          [{ script: 'sub' }, { script: 'super' }],
          ['clean'],
          ['undo', 'redo'],
        ],
        handlers: {
          undo: undoChange,
          redo: redoChange,
        },
      },
      history: {
        delay: 1000,
        maxStack: 100,
        userOnly: true,
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [],
  );

  const quillModuleWithImage = useMemo(
    () => ({
      ...quillModule,
      toolbar: {
        container: [...quillModule.toolbar.container, ['image']],
      },
    }),
    [],
  );

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      {readOnly ? (
        <MDInput fullWidth value={stripHtml(value || '').result} disabled />
      ) : hasImage ? (
        <MDBox>
          {/* work around to ensure re-render/rendering with different toolbar*/}
          <ReactQuill
            theme="snow"
            value={value ? value : ''}
            modules={quillModuleWithImage}
            onChange={debounce(onChange, 500)}
            ref={quillRef}
          />
        </MDBox>
      ) : (
        <ReactQuill
          theme="snow"
          value={value ? value : ''}
          modules={quillModule}
          onChange={debounce(onChange, 500)}
          ref={quillRef}
        />
      )}
    </MDEditorRoot>
  );
};

export default MDEditor;
