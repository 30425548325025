import { Clonable, clonable } from '@hookstate/clonable';
import { comparable, Comparable } from '@hookstate/comparable';
import {
  extend,
  hookstate,
  InferStateExtensionType,
  none,
  useHookstate,
} from '@hookstate/core';
import { devtools } from '@hookstate/devtools';
import { Initializable, initializable } from '@hookstate/initializable';
import { Snapshotable, snapshotable } from '@hookstate/snapshotable';
import { Validation, validation } from '@hookstate/validation';
import update from 'immutability-helper';
import { cloneDeep, isArray, isEmpty, isEqual } from 'lodash';
import { stripHtml } from 'string-strip-html';

import { useAutocompleteT } from 'translate';

import { defaultQuestion } from 'constants/defaults';
import {
  CoreQuestionType,
  FacetType,
  Question,
  QuestionAnswer,
  QuestionErrors,
  QuestionFields,
} from 'types/questions';

function extensionsGroupTwo<S, E>() {
  return extend<S, E, Validation>();
}

type ExtendedGroupTwo = InferStateExtensionType<typeof extensionsGroupTwo>;

function extensions<S, E>() {
  return extend<
    S,
    E,
    Clonable,
    Comparable,
    Snapshotable,
    Initializable,
    ExtendedGroupTwo
  >();
}

type Extended = InferStateExtensionType<typeof extensions>;

const typesState = hookstate<{ [type: string]: CoreQuestionType }>(
  {},
  devtools({ key: 'questionTypes' }),
);

const facetState = hookstate<{
  [type: string]: FacetType[];
}>({}, devtools({ key: 'questionFacets' }));

const questionState = hookstate<Question, Extended>(
  { ...defaultQuestion },
  extend(
    clonable((v) => (v === undefined ? undefined : cloneDeep(v))),
    comparable((v1, v2) => +!isEqual(v1, v2)),
    snapshotable(),
    initializable((s) => {
      s.snapshot();
    }),
    extend(validation(), devtools({ key: 'questions' })),
  ),
);

const useQuestionState = () => {
  const types = useHookstate(typesState);
  const facets = useHookstate(facetState);
  const question = useHookstate(questionState);
  const { T } = useAutocompleteT();

  useHookstate(question.snapshot(undefined, 'lookup')).get({ noproxy: true });

  // Question Validations
  question.type.validate((type) => !isEmpty(type), T('validation.type'));
  question.rank.validate((rank) => !isEmpty(rank), T('validation.rank'));
  question.series.validate(
    (series) => !isEmpty(series),
    T('validation.series'),
  );
  question.text.validate(
    (text) => !isEmpty(stripHtml(text).result),
    T('validation.text'),
  );
  question.answers.validate((answers) => {
    for (const { correct } of answers) {
      if (correct) {
        return true;
      }
    }
    return false;
  }, T('validation.answers'));
  question.ro.validate((text) => !isEmpty(text), T('validation.ro'), 'warning');
  question.ro.validate(
    (text) =>
      isEmpty(text)
        ? true
        : /[0-9]{1,3} [sS]{1}-[0-9]{1,2} \([0-9]{4}-[0-9]{2}-[0-9]{2}\)/g.test(
            text,
          ),
    T('validation.roPattern'),
  );
  question.feedback.validate((feedback) => {
    const currentQuestionType = question.type.get();
    if (currentQuestionType) {
      const currentTypes = types.get();
      if (
        currentTypes &&
        currentTypes[currentQuestionType] &&
        currentTypes[currentQuestionType]['hasGeneralFeedback']
      ) {
        return !!stripHtml(feedback).result.length;
      }
    }
    return true;
  }, T('validation.feedback'));

  return {
    // Types Store
    get questionTypes() {
      const currentTypes = types.get({ noproxy: true });
      return Object.keys(currentTypes).length > 0 ? currentTypes : undefined;
    },
    setQuestionType(type, details?: CoreQuestionType | undefined) {
      if (details) {
        types.nested(type).set(details);
      } else {
        types.nested(type).set(none);
      }
    },
    // Facet Store
    get questionFacets() {
      const currentFacets = facets.get({ noproxy: true });
      return Object.keys(currentFacets).length > 0 ? currentFacets : undefined;
    },
    setQuestionFacet(facet, details?: FacetType[]) {
      if (details) {
        facets.nested(facet).set(details);
      } else {
        types.nested(facet).set(none);
      }
    },
    // Question Store
    get question() {
      return question.get();
    },
    get questionAnswers() {
      return question.answers.map((answer) => {
        answer.text.validate(
          (text) => stripHtml(text || '').result.length > 0,
          'Answer should not be empty',
        );
        answer.feedback.validate((feedback) => {
          const questionType = question.nested('type').get();
          const questionOptionsState = types.nested(questionType);
          const hasFeedback = questionOptionsState.nested('hasAnswerFeedback')
            ? questionOptionsState.nested('hasAnswerFeedback').get()
            : undefined;
          if (hasFeedback) {
            return stripHtml(feedback || '').result.length > 0;
          }
          return true;
        }, 'Feedback should not be empty');
        return answer.get();
      });
    },
    get questionErrors(): QuestionErrors {
      return question
        .errors((e) => e.severity === 'error')
        .reduce((acc, error) => {
          const { path, message } = error;
          const [questionField, answerIndex, answerField] = path;

          if (questionField === 'answers') {
            if (path.length === 3) {
              return {
                ...acc,
                [questionField]: {
                  ...acc[questionField],
                  [answerIndex]:
                    acc[questionField] && acc[questionField][answerIndex]
                      ? {
                          ...acc[questionField][answerIndex],
                          [answerField]: message,
                        }
                      : {
                          [answerField]: message,
                        },
                },
              };
            }

            return {
              ...acc,
              [questionField]: {
                ...acc[questionField],
                message,
              },
            };
          }
          return { ...acc, [questionField]: message };
        }, {});
    },
    get questionWarnings(): QuestionErrors {
      return question
        .errors((e) => e.severity === 'warning')
        .reduce((acc, error) => {
          const { path, message } = error;
          const [questionField] = path;
          return { ...acc, [questionField]: message };
        }, {});
    },
    get questionHasType() {
      return !!question.type.get();
    },
    get questionIsInvalid() {
      return !!question.errors((e) => e.severity === 'error').length;
    },
    get questionIsValid() {
      return question.valid();
    },
    get questionOptions() {
      const currentQuestionType = question.type.get();
      const currentTypes = types.get();

      return currentQuestionType && currentTypes[currentQuestionType]
        ? currentTypes[currentQuestionType]
        : ({} as CoreQuestionType);
    },
    questionAddEmptyAnswer() {
      question.answers.merge([{ correct: false, feedback: '', text: '' }]);
    },
    questionRemoveAnswer(index: number) {
      question.answers.nested(index).set(none);
    },
    questionReset() {
      question.set({ ...defaultQuestion });
      question.snapshot();
    },
    questionUpdate(questionData: Question) {
      question.set(questionData);
    },
    questionUpdateAnswer(index: number, answer: QuestionAnswer) {
      const { text, feedback, correct } = answer;
      let updatedAnswerFields = {};
      if (text) {
        updatedAnswerFields = {
          ...updatedAnswerFields,
          text,
        };
      }
      if (feedback) {
        updatedAnswerFields = {
          ...updatedAnswerFields,
          feedback,
        };
      }
      if (correct !== undefined) {
        updatedAnswerFields = {
          ...updatedAnswerFields,
          correct,
        };
      }
      question.answers.nested(index).merge(updatedAnswerFields);
    },
    questionUpdateField(info: { field: QuestionFields; value: any }) {
      const { field, value } = info;
      if (typeof value === 'object' && !isArray(value) && value !== null) {
        return question.nested(field).merge(value);
      }
      return question.nested(field).set(value);
    },
    get questionModified() {
      return question.modified();
    },
    questionSnapshot() {
      return question.snapshot();
    },
    questionRestore() {
      return question.rollback();
    },
    questionReorderAnswer(sourceIndex: number, destinationIndex: number) {
      question.answers.set((currentAnswers) => {
        return update(currentAnswers, {
          $splice: [
            [sourceIndex, 1],
            [destinationIndex, 0, currentAnswers[sourceIndex]],
          ],
        });
      });
    },
  };
};

export default useQuestionState;
