import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MDBox from 'components/MDB/MDBox';

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setTransparentNavbar,
  useMaterialUIController,
} from '../../../providers/MaterialUIProvider';
import { useRealmUser } from '../../../providers/RealmUserProvider';
import Breadcrumbs from '../Breadcrumbs';
import NotificationItem from './NotificationItem';
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarRow,
} from './styles';

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openProfileMenu, setOpenProfileMenu] = useState<any>(false);
  const route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, false);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    // window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    // return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenProfileMenu = (event: any) =>
    setOpenProfileMenu(event.currentTarget);
  const handleCloseProfileMenu = () => setOpenProfileMenu(false);

  // We are consuming our user-management context to
  // get & set the user details here
  const { logOutUser } = useRealmUser();

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="primary"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={navbarContainer}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
          <IconButton
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? 'menu_open' : 'menu'}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={'white'}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                onClick={handleOpenProfileMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              <Menu
                anchorEl={openProfileMenu}
                anchorReference={undefined}
                open={Boolean(openProfileMenu)}
                onClose={handleCloseProfileMenu}
                sx={{ mt: 2 }}
              >
                <NotificationItem
                  icon={<Icon>lock</Icon>}
                  title="Logout"
                  onClick={logOutUser}
                />
              </Menu>
              {/*<IconButton*/}
              {/*  size="small"*/}
              {/*  disableRipple*/}
              {/*  color="inherit"*/}
              {/*  sx={navbarIconButton}*/}
              {/*  onClick={handleConfiguratorOpen}*/}
              {/*>*/}
              {/*  <Icon sx={iconsStyle}>settings</Icon>*/}
              {/*</IconButton>*/}
              {/*<IconButton*/}
              {/*  size="small"*/}
              {/*  color="inherit"*/}
              {/*  sx={navbarIconButton}*/}
              {/*  onClick={handleOpenMenu}*/}
              {/*>*/}
              {/*  <MDBadge badgeContent={9} color="error" size="xs" circular>*/}
              {/*    <Icon sx={iconsStyle}>notifications</Icon>*/}
              {/*  </MDBadge>*/}
              {/*</IconButton>*/}
              {/*{renderMenu()}*/}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
