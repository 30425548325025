import en from 'i18n/en.json';
import LoginPage from 'pages/Login';
import ResetPasswordPage from 'pages/ResetPassword';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import { Talkr } from 'talkr';

import Sidenav from 'layouts/components/Sidenav';

import {
  setMiniSidenav,
  useMaterialUIController,
} from 'providers/MaterialUIProvider';
import { RealmProviders } from 'providers/RealmProviders';

import Loading from 'components/Loading';
import Notifications from 'components/Notifications';
import PrivateRoute from 'components/PrivateRoute';

import { RouteItem } from 'types/routes';

import brandDark from 'assets/images/logo-ct-dark.png';
import brandWhite from 'assets/images/logo-ct.png';

function App() {
  const [
    {
      miniSidenav,
      direction,
      layout,
      sidenavColor,
      transparentSidenav,
      whiteSidenav,
      darkMode,
    },
    dispatch,
  ] = useMaterialUIController();

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   if (document.scrollingElement) {
  //     document.scrollingElement.scrollTop = 0;
  //   }
  // }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map((route: RouteItem) => {
      if (route.collapse) {
        if (route.component) {
          return (
            <Route element={route.component} key={route.key}>
              {getRoutes(route.collapse)}
            </Route>
          );
        }
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }
      return null;
    });

  return (
    <RealmProviders>
      <Talkr languages={{ en }} defaultLanguage="en">
        <BrowserRouter>
          {layout === 'dashboard' && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="EST Mission Control"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/password-reset" element={<ResetPasswordPage />} />
            <Route element={<PrivateRoute />}>{getRoutes(routes)}</Route>
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </BrowserRouter>
        <Notifications />
        <Loading />
      </Talkr>
    </RealmProviders>
  );
}

export default App;
