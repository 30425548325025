import {
  clone,
  cloneDeep,
  escapeRegExp,
  isArray,
  isObject,
  isString,
  mapValues,
} from 'lodash';

const searchAndReplace = (text, term) => {
  return text.replace(
    new RegExp('(\\s|\\>)(' + escapeRegExp(term) + ')(\\s|\\<)', 'gi'),
    '$1<mark>$2</mark>$3',
  );
};

export const highlightUnmarkWord = (text: string) =>
  text.replaceAll('<mark>', '').replaceAll('</mark>', '');

export const highlightMarkWord = (
  text: string,
  term: string | string[] | undefined,
) => {
  let cleanText = highlightUnmarkWord(clone(text));

  if (term && term.length > 0) {
    if (isString(term)) {
      return searchAndReplace(cleanText, term);
    } else if (isArray(term)) {
      for (const t of term) {
        cleanText = searchAndReplace(cleanText, t);
      }
    }
  }
  return cleanText;
};

export const highlightMarkWordsInObject = (obj, findStrings: string[]) => {
  const highlightMarkWordInObject = (obj, findStr) => {
    return mapValues(obj, (value) => {
      if (isString(value)) {
        return searchAndReplace(value, findStr);
      } else if (isObject(value)) {
        return highlightMarkWordInObject(value, findStr);
      } else {
        return value;
      }
    });
  };

  let objCopy = cloneDeep(obj);

  for (const findString of findStrings) {
    objCopy = highlightMarkWordInObject(objCopy, findString);
  }
  return objCopy;
};

export default {
  highlightMarkWord,
  highlightUnmarkWord,
  highlightMarkWordsInObject,
};
