import { Grid, Radio, Skeleton } from '@mui/material';
import { Parser } from 'html-to-react';
import { sample, shuffle } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { defaultQuestion } from '../../constants/defaults';
import useQuestion from '../../graphql/hooks/useQuestion';
import { Question, QuestionAnswer } from '../../types/questions';
import MDBox from '../MDB/MDBox';
import MDButton from '../MDB/MDButton';
import MDTypography from '../MDB/MDTypography';
import './transitions.css';

interface Props {
  id?: string;
  onAnswered?: () => void;
}

const QuickTake: FC<Props> = ({ id, onAnswered }) => {
  const [answered, setAnswered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState<Question>(defaultQuestion);
  const [selectedAnswer, setSelectedAnswer] = useState<QuestionAnswer | null>(
    null,
  );

  const bodyClass = 'body2';
  const skeletonWidths = ['80%', '40%', '65%'];

  const { getQuestion } = useQuestion();
  useEffect(() => {
    setLoading(true);
    setAnswered(false);
    setSelectedAnswer(null);
    setQuestion(defaultQuestion);
    if (id) {
      getQuestion(id)
        .then((q) => {
          setQuestion({
            ...q,
            answers: shuffle(q?.answers),
          } as unknown as Question);
        })
        .then(() => setLoading(false));
      //6496f3609362175b11b583f8
    }
  }, [id]);

  const selectAnswer = (answer) => {
    if (answered) return;

    setSelectedAnswer(answer);
  };

  return (
    <MDBox>
      {loading ? (
        <MDBox>
          <MDBox mb={2}>
            <Skeleton variant="rounded" width="100%" height={150} />
          </MDBox>
          <MDBox mb={2}>
            <Skeleton
              variant="rounded"
              width={sample(skeletonWidths)}
              height={50}
            />
          </MDBox>
          <MDBox mb={2}>
            <Skeleton
              variant="rounded"
              width={sample(skeletonWidths)}
              height={50}
            />
          </MDBox>
          <MDBox mb={2}>
            <Skeleton
              variant="rounded"
              width={sample(skeletonWidths)}
              height={50}
            />
          </MDBox>
          <MDBox mb={2}>
            <Skeleton
              variant="rounded"
              width={sample(skeletonWidths)}
              height={50}
            />
          </MDBox>
        </MDBox>
      ) : (
        <>
          <MDTypography variant={bodyClass} mb={3} component="div">
            {Parser().parse(question.text)}
          </MDTypography>

          {question.answers.map((answer, index) => (
            <Grid
              key={index}
              container
              onClick={() => selectAnswer(answer)}
              sx={{ cursor: answered ? 'cursor' : 'pointer' }}
              mb={3}
            >
              <Grid item sx={{ width: '50px', marginTop: '-7px' }}>
                <Radio checked={answer == selectedAnswer} />
              </Grid>
              <Grid item xs>
                <MDTypography variant={bodyClass} component="div">
                  {Parser().parse(answer.text)}
                </MDTypography>
                {answered && (
                  <MDTypography
                    variant={bodyClass}
                    component="div"
                    color={answer.correct ? 'success' : 'error'}
                  >
                    {Parser().parse(answer.feedback)}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          ))}

          {answered && question.feedback.length > 20 && (
            <MDTypography variant={bodyClass} mb={3} component="div">
              {Parser().parse(question.feedback)}
            </MDTypography>
          )}
        </>
      )}
      {!answered && (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <MDButton
              color="primary"
              disabled={!selectedAnswer || loading}
              onClick={() => {
                setAnswered(true);
                onAnswered && onAnswered();
              }}
            >
              Submit
            </MDButton>
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default QuickTake;
