import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Checkbox, FormHelperText, Icon, Radio, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { FC, useState } from 'react';
import { useLongPress } from 'use-long-press';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDEditor from 'components/MDB/MDEditor';
import MDTypography from 'components/MDB/MDTypography';

import { QuestionAnswerErrors } from 'types/questions';

import { useQuestionState, useSearchState } from 'stores';

import { highlightMarkWord, highlightUnmarkWord } from 'helpers';

import './transitions.css';

interface Props {
  index: number;
  text: string;
  feedback: string;
  correct: boolean;
  errors?: QuestionAnswerErrors;
}

const Answer: FC<Props> = ({
  index,
  text = '',
  feedback = '',
  correct = false,
  errors,
}) => {
  const [fade, setFade] = useState('fade-in');

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: index + 1 });

  const {
    questionRemoveAnswer,
    questionUpdateAnswer,
    questionOptions,
    questionAnswers,
  } = useQuestionState();

  const { searchHighlight, searchText } = useSearchState();

  const bind = useLongPress(
    () => {
      setFade('fade-out');
      setTimeout(() => {
        questionRemoveAnswer(index);
        setFade('fade-in');
      }, 500);
    },
    {
      threshold: 1000,
      cancelOnMovement: true,
    },
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <MDBox mb={2} className={fade} ref={setNodeRef} style={style}>
      <Grid container mb={2}>
        <Grid item style={{ width: '36px' }}>
          <MDBox
            display="flex"
            alignContent="center"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {questionOptions.hasMultipleCorrect ? (
              <Checkbox
                checked={correct}
                onChange={({ target }) => {
                  const { checked } = target;
                  questionUpdateAnswer(index, {
                    correct: checked,
                  });
                }}
              />
            ) : (
              <Radio
                checked={correct}
                onChange={({ target }) => {
                  const { checked } = target;
                  if (checked !== correct) {
                    for (const answer in questionAnswers) {
                      questionUpdateAnswer(parseInt(answer, 10), {
                        correct: false,
                      });
                    }
                    questionUpdateAnswer(index, {
                      correct: checked,
                    });
                  }
                }}
              />
            )}

            {!questionOptions.isTrueFalse && (
              <>
                <Tooltip
                  title="Click and hold to delete"
                  placement="left"
                  enterDelay={1000}
                >
                  <MDButton
                    iconOnly
                    size="small"
                    variant="text"
                    color="error"
                    {...bind()}
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>

                <MDButton
                  {...listeners}
                  {...attributes}
                  iconOnly
                  size="small"
                  variant="text"
                  color="secondary"
                >
                  <Icon>reorder</Icon>
                </MDButton>
              </>
            )}
          </MDBox>
        </Grid>
        <Grid item xs>
          <MDEditor
            value={searchHighlight ? highlightMarkWord(text, searchText) : text}
            readOnly={questionOptions.isTrueFalse}
            hideToolbar={questionOptions.isTrueFalse}
            onChange={(value) =>
              questionUpdateAnswer(index, {
                text: highlightUnmarkWord(value),
              })
            }
          />
          <FormHelperText error>{errors?.text}</FormHelperText>
          {questionOptions.hasAnswerFeedback && (
            <MDBox>
              <MDTypography variant="body2">Feedback:</MDTypography>
              <MDEditor
                value={
                  searchHighlight
                    ? highlightMarkWord(feedback, searchText)
                    : feedback
                }
                onChange={(value) =>
                  questionUpdateAnswer(index, {
                    feedback: highlightUnmarkWord(value),
                  })
                }
              />
              <FormHelperText error>{errors?.feedback}</FormHelperText>
            </MDBox>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Answer;
