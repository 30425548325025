import { hookstate, useHookstate } from '@hookstate/core';

interface GlobalState {
  loading: boolean;
  message: string;
}

const globalState = hookstate<GlobalState>({
  loading: false,
  message: '',
});

const useGlobalState = () => {
  const state = useHookstate(globalState);

  return {
    get globalLoading() {
      return state.loading.get();
    },
    get globalMessage() {
      return state.message.get();
    },
    globalLoadingSet(loading = false, message = '') {
      state.loading.set(loading);
      state.message.set(message);
    },
  };
};

export default useGlobalState;
