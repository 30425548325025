export const questionColor = [
  'secondary',
  'error',
  'default',
  'primary',
  'info',
  'success',
  'warning',
] as const;

export type QuestionColor = (typeof questionColor)[number];
