import { MenuItemProps } from '@mui/material';
import Link from '@mui/material/Link';
// @mui material components
import MenuItem from '@mui/material/MenuItem';
import { FC, forwardRef, ReactNode } from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from '../../../components/MDB/MDBox';
import MDTypography from '../../../components/MDB/MDTypography';

// custom styles for the NotificationItem
// import menuItem from "examples/Items/NotificationItem/styles";

// Declaring props types for NotificationItem
interface Props extends MenuItemProps {
  icon: ReactNode;
  title: string;
  [key: string]: any;
}

const NotificationItem: FC<Props> = forwardRef(
  ({ icon, title, ...rest }, ref) => (
    <MenuItem {...rest} ref={ref}>
      <MDBox
        component={Link}
        py={0.5}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
          {icon}
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
          {title}
        </MDTypography>
      </MDBox>
    </MenuItem>
  ),
);

export default NotificationItem;
