import {
  Card,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Parser } from 'html-to-react';
import { capitalize, includes, isArray, isEmpty, isObject } from 'lodash';
import log from 'loglevel';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuestion } from 'graphql/hooks/useQuestion';

import ActionDialog from 'components/ActionDialog';
import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';

import { isValidColor } from 'utils/colors';

import { QuestionColor } from 'constants/colors';
import { Question } from 'types/questions';

import { useBulkEditState, useGlobalState, useQuestionState } from 'stores';

import { highlightMarkWordsInObject, labelDisplay } from 'helpers';

import QuickTake from '../Question/QuickTake';

interface Props {
  hit: any;
  onChange: (noCache?) => void;
}

const SearchHit: FC<Props> = ({ hit, onChange }) => {
  const [highlightedHit, setHighlightedHit] = useState<Question | undefined>(
    undefined,
  );
  const [hitColor, setHitColor] = useState<QuestionColor>('secondary');
  const [hitTypeLabel, setHitTypeLabel] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isQuickTakeModalOpen, setIsQuickTakeModalOpen] = useState(false);
  const [isQuickTakeAnswered, setIsQuickTakeAnswered] = useState(false);

  const navigate = useNavigate();
  const { bulkEditToggleQuestion, bulkEditSelectedQuestions, bulkEditActive } =
    useBulkEditState();
  const { questionTypes } = useQuestionState();
  const { globalLoadingSet } = useGlobalState();

  const { duplicateQuestion, deleteQuestions } = useQuestion();

  useEffect(() => {
    if (questionTypes && questionTypes[hit.type]) {
      if (isValidColor(questionTypes[hit.type].colorName as string)) {
        setHitColor(
          questionTypes[hit.type].colorName as unknown as QuestionColor,
        );
      }
      setHitTypeLabel(
        questionTypes[hit.type].label
          ? questionTypes[hit.type].label
          : hit.type,
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (hit.highlights) {
        setHighlightedHit(highlightMarkWordsInObject(hit, hit.highlights));
      }
    })();
  }, [hit.highlights]);

  const handleNavigateToId = (_id?) => {
    navigate(`/questions/edit/${_id ? _id : hit._id}`, {
      state: { highlights: hit.highlights },
    });
  };

  const handleQuickTakeClose = () => {
    setIsQuickTakeModalOpen(false);
    setIsQuickTakeAnswered(false);
  };

  return (
    <MDBox mb={2}>
      <Card
        sx={
          includes(bulkEditSelectedQuestions, hit._id)
            ? {
                border: 1,
                borderColor: hitColor + '.main',
              }
            : {}
        }
      >
        <MDBox p={2} pt={1}>
          <Grid
            container
            spacing={2}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Chip
                label={
                  <MDTypography
                    variant="caption"
                    textTransform="uppercase"
                    fontWeight="medium"
                    color={'white'}
                  >
                    {hitTypeLabel}
                  </MDTypography>
                }
                color={hitColor as QuestionColor}
                size="small"
              />
            </Grid>
            <Grid item>
              {bulkEditActive ? (
                <>
                  <MDButton
                    circular
                    iconOnly
                    color={hitColor}
                    variant="text"
                    disabled
                  >
                    <Icon>ads_click</Icon>
                  </MDButton>
                  <MDButton
                    circular
                    iconOnly
                    color={hitColor}
                    variant="text"
                    disabled
                  >
                    <Icon>edit_note</Icon>
                  </MDButton>
                  <MDButton
                    circular
                    iconOnly
                    color={hitColor}
                    variant="text"
                    disabled
                  >
                    <Icon>copy_document</Icon>
                  </MDButton>
                  <MDButton
                    circular
                    iconOnly
                    color={hitColor}
                    variant="text"
                    disabled
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </>
              ) : (
                <>
                  <Tooltip title="Quick Take" placement="bottom">
                    <MDButton
                      circular
                      iconOnly
                      color={hitColor}
                      variant="text"
                      disabled={bulkEditActive}
                      onClick={() => setIsQuickTakeModalOpen(true)}
                    >
                      <Icon>ads_click</Icon>
                    </MDButton>
                  </Tooltip>
                  <Tooltip title="Edit" placement="bottom">
                    <MDButton
                      circular
                      iconOnly
                      color={hitColor}
                      variant="text"
                      disabled={bulkEditActive}
                      onClick={() => handleNavigateToId()}
                    >
                      <Icon>edit_note</Icon>
                    </MDButton>
                  </Tooltip>
                  <Tooltip title="Duplicate" placement="bottom">
                    <MDButton
                      circular
                      iconOnly
                      color={hitColor}
                      variant="text"
                      disabled={bulkEditActive}
                      onClick={() => setIsDuplicateModalOpen(true)}
                    >
                      <Icon>copy_document</Icon>
                    </MDButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="bottom">
                    <MDButton
                      circular
                      iconOnly
                      color={hitColor}
                      variant="text"
                      disabled={bulkEditActive}
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                </>
              )}
              <Tooltip
                title="Select Question for Bulk Actions"
                placement="bottom"
              >
                <MDButton
                  circular
                  iconOnly
                  color={hitColor}
                  variant="text"
                  onClick={() => bulkEditToggleQuestion(hit._id)}
                >
                  <Icon>
                    {includes(bulkEditSelectedQuestions, hit._id)
                      ? 'check_box_outline'
                      : 'check_box_outline_blank'}
                  </Icon>
                </MDButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} flexDirection="row">
            <Grid item xs>
              {!isEmpty(highlightedHit) ? (
                <>
                  <MDTypography
                    variant="body2"
                    component="div"
                    color="text"
                    mb={2}
                  >
                    {Parser().parse(highlightedHit.text)}
                  </MDTypography>
                  <MDBox pl={4}>
                    <ul>
                      {highlightedHit.answers &&
                        isObject(highlightedHit.answers) &&
                        Object.values(highlightedHit.answers)
                          .filter((answer) =>
                            includes(JSON.stringify(answer), '<mark>'),
                          )
                          .map((answer, index) => (
                            <MDBox component="li" key={index} mb={2}>
                              {includes(answer.text, '<mark>') && (
                                <MDTypography
                                  variant="body2"
                                  component="div"
                                  color="text"
                                >
                                  {Parser().parse(answer.text)}
                                </MDTypography>
                              )}
                              {includes(answer.feedback, '<mark>') && (
                                <MDBox
                                  pt={1}
                                  mt={1}
                                  sx={{
                                    borderTop: '1px solid',
                                    borderColor: grey[200],
                                  }}
                                >
                                  <MDTypography
                                    variant="body2"
                                    component="div"
                                    color="text"
                                  >
                                    {Parser().parse(answer.feedback)}
                                  </MDTypography>
                                </MDBox>
                              )}
                            </MDBox>
                          ))}
                    </ul>
                  </MDBox>
                  {includes(hit.feedback, '<mark>') && (
                    <MDTypography variant="body2" component="div" color="text">
                      {Parser().parse(hit.feedback)}
                    </MDTypography>
                  )}
                </>
              ) : (
                <MDTypography variant="body2" component="div" color="text">
                  {Parser().parse(hit.text)}
                </MDTypography>
              )}
            </Grid>
            <Grid item sx={{ width: '180px' }}>
              <MDBox mb={1}>
                <MDTypography variant="body2" component="p" color="text">
                  <strong>ID:</strong> {hit.questionId}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="body2" component="p" color="text">
                  <strong>Rank:</strong>
                  <br />
                  {capitalize(hit.rank)}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="body2" component="p" color="text">
                  <strong>Series:</strong>
                  <br />
                  {capitalize(hit.series.join(', '))}
                </MDTypography>
              </MDBox>
              {hit.procedures.length > 0 && (
                <MDBox mb={1}>
                  <MDTypography variant="body2" component="p" color="text">
                    <strong>Procedures:</strong>
                    <br />
                    {capitalize(hit.procedures.join(', '))}
                  </MDTypography>
                </MDBox>
              )}
              {hit.tags.length > 0 && (
                <MDBox mb={1}>
                  <MDTypography variant="body2" component="p" color="text">
                    <strong>Tags:</strong>
                    <br />
                    {capitalize(hit.tags.join(', '))}
                  </MDTypography>
                </MDBox>
              )}
              {hit?.ro?.length > 0 && (
                <MDBox mb={1}>
                  <MDTypography variant="body2" component="p" color="text">
                    <strong>RO:</strong>
                    <br />
                    {labelDisplay(hit.ro)}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
          </Grid>
          {isArray(hit.highlights) && (
            <MDBox mb={-2}>
              <MDTypography variant="caption">
                Matched Search Term{hit.highlights.length > 1 ? 's: ' : ': '}
                {hit.highlights.map((word) => `"${word}"`).join(', ')}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </Card>
      <ActionDialog
        onAction={async () => {
          await globalLoadingSet(true, 'Deleting');
          setIsDeleteModalOpen(false);
          deleteQuestions([hit._id])
            .then(onChange)
            .catch((e) => log.error(e))
            .then(() => globalLoadingSet(false));
        }}
        actionLabel="Delete"
        onClose={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
        message={hit.text}
        title="Are you sure you want to delete the following question?"
        severity="error"
      />
      <ActionDialog
        onAction={async () => {
          await globalLoadingSet(true, 'Duplicating');
          setIsDuplicateModalOpen(false);
          duplicateQuestion(hit._id)
            .then(({ _id }) => {
              if (_id) {
                handleNavigateToId(_id);
              }
            })
            .catch((e) => log.error(e))
            .then(() => globalLoadingSet(false));
        }}
        actionLabel="Duplicate"
        onClose={() => setIsDuplicateModalOpen(false)}
        open={isDuplicateModalOpen}
        message={hit.text}
        title="Are you sure you want to duplicate this question?"
      />
      <Dialog
        open={isQuickTakeModalOpen}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleQuickTakeClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <QuickTake
            id={hit._id}
            onAnswered={() => setIsQuickTakeAnswered(true)}
          />
          {isQuickTakeAnswered && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MDButton onClick={handleQuickTakeClose}>Done</MDButton>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </MDBox>
  );
};

export default SearchHit;
