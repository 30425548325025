import Card from '@mui/material/Card';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isStrongPassword } from 'validator';

import BasicLayout from 'layouts/BasicLayout';

import { useRealmUser } from 'providers/RealmUserProvider';

import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDInput from 'components/MDB/MDInput';

import bgImage from 'assets/images/bg-sign-in-basic.jpeg';

import useNotifications from '../stores/notification';
import { useAutocompleteT } from '../translate';

const ResetPasswordPage: FC = () => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const token = queryParameters.get('token');
  const tokenId = queryParameters.get('tokenId');

  const { emailPasswordReset } = useRealmUser();
  const { addNotification } = useNotifications();
  const { T } = useAutocompleteT();

  useEffect(() => {
    if (!token || !tokenId) {
      navigate('/');
    }
  }, []);

  const [form, setForm] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  // This function will be called whenever the user edits the form.
  const onFormInputChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  // This function gets fired when the user clicks on the "Login" button.
  const onSubmit = async () => {
    emailPasswordReset({ token, tokenId, password: form.newPassword })
      .then(() =>
        addNotification({
          severity: 'success',
          message: 'Please log in with your new password.',
        }),
      )
      .catch(() =>
        addNotification({
          message:
            'Something went wrong, please request a new password reset and try again.',
          severity: 'error',
        }),
      )
      .then(() => navigate('/'));
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="New Password"
                name="newPassword"
                value={form.newPassword}
                autoComplete="off"
                error={
                  form.newPassword.length !== 0 &&
                  !isStrongPassword(form.newPassword)
                }
                fullWidth
                helperText={
                  form.newPassword.length !== 0 &&
                  !isStrongPassword(form.newPassword)
                    ? T('validation.passwords.strong')
                    : ''
                }
                onChange={onFormInputChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="off"
                value={form.confirmPassword}
                error={
                  form.confirmPassword.length > 0 &&
                  form.newPassword !== form.confirmPassword
                }
                fullWidth
                helperText={
                  form.confirmPassword.length &&
                  form.newPassword !== form.confirmPassword
                    ? T('validation.passwords.match')
                    : ''
                }
                onChange={onFormInputChange}
              />
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={onSubmit}
                disabled={
                  !token ||
                  !tokenId ||
                  form.confirmPassword.length === 0 ||
                  form.newPassword.length < 5 ||
                  form.newPassword !== form.confirmPassword ||
                  !isStrongPassword(form.newPassword)
                }
              >
                Reset Password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default ResetPasswordPage;
