import { hookstate, useHookstate } from '@hookstate/core';

interface AuthState {
  isSignedIn: boolean;
  id: string;
  claims: {
    admin: boolean;
    permissions: string[];
  };
}

const authState = hookstate<AuthState>({
  isSignedIn: false,
  id: '',
  claims: {
    admin: false,
    permissions: [],
  },
});

const useAuthState = () => {
  const state = useHookstate(authState);

  return {
    get isSignedIn() {
      return state.isSignedIn.get();
    },
    get isAdmin() {
      return state.claims.admin.get();
    },
    get id() {
      return state.id.get();
    },
    get permissions() {
      return state.claims.permissions.get();
    },
    setAuth(newState: AuthState) {
      state.set((state) => ({ ...state, ...newState }));
    },
  };
};

export default useAuthState;
