import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MDBox from '../components/MDB/MDBox';
import {
  setLayout,
  useMaterialUIController,
} from '../providers/MaterialUIProvider';
import DashboardNavbar from './components/DashboardNavbar';
import Footer from './components/Footer';

const AdvancedLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DashboardNavbar />
      <MDBox pt={1} pb={2}>
        {children}
      </MDBox>
      <Footer
        company={{
          href: 'https://www.elitestrategictraining.com/',
          name: 'Elite St',
        }}
        links={[
          {
            href: 'https://www.elitestrategictraining.com/',
            name: 'Website',
          },
          {
            href: 'https://lms.elitestrategictraining.com/',
            name: 'Learning Management System',
          },
        ]}
      />
    </MDBox>
  );
};

export default AdvancedLayout;
