import React, { FC, ReactNode } from 'react';

import RealmApolloProvider from './RealmApolloProvider';
import RealmAppProvider from './RealmAppProvider';
import { RealmUserProvider } from './RealmUserProvider';

interface Props {
  children: ReactNode;
}

export const RealmProviders: FC<Props> = ({ children }) => {
  return (
    <RealmAppProvider>
      <RealmUserProvider>
        <RealmApolloProvider>{children}</RealmApolloProvider>
      </RealmUserProvider>
    </RealmAppProvider>
  );
};
