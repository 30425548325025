import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Parser } from 'html-to-react';
import React, { FC } from 'react';

import MDButton from './MDB/MDButton';

interface Props {
  onAction: () => any;
  actionLabel: string;
  onClose: () => any;
  open: boolean;
  title?: string;
  message: string;
  severity?:
    | 'secondary'
    | 'default'
    | 'white'
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | undefined;
}
const ActionDialog: FC<Props> = ({
  open,
  title,
  message = '',
  actionLabel = 'Confirm',
  severity = 'secondary',
  onAction,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {Parser().parse(message)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} autoFocus>
          Cancel
        </MDButton>
        <MDButton onClick={onAction} color={severity}>
          {actionLabel}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
