import { ApolloQueryResult } from '@apollo/client';
import { Divider, Icon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import log from 'loglevel';
import React, { FC, useState } from 'react';

import { SearchQuestionsQuery } from 'graphql/apollo';
import useQuestion from 'graphql/hooks/useQuestion';

import ActionDialog from 'components/ActionDialog';
import MDBadge from 'components/MDB/MDBadge';
import MDButton from 'components/MDB/MDButton';
import SearchEdit from 'components/Search/SearchEdit';

import { useBulkEditState, useGlobalState } from 'stores';

import SearchClone from './SearchClone';

const SearchBulkActions: FC<{
  onChange: (noCache?) => Promise<ApolloQueryResult<SearchQuestionsQuery>>;
}> = ({ onChange }) => {
  const [editActive, setEditActive] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [cloneActive, setCloneActive] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonOpen = Boolean(anchorEl);

  const {
    bulkEditActive,
    bulkEditSelectedCount,
    bulkEditClearSelectedQuestions,
    bulkEditSelectedQuestions,
    bulkEditSetSelectVisibleSearchResults,
  } = useBulkEditState();

  const { deleteQuestions } = useQuestion();
  const { globalLoadingSet } = useGlobalState();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClearAll = () => {
    bulkEditClearSelectedQuestions();
    handleClose();
  };
  const handleSelectAll = () => {
    bulkEditSetSelectVisibleSearchResults();
    handleClose();
  };

  const handleEdit = () => {
    setEditActive(true);
    handleClose();
  };

  const handleEditOnClose = () => {
    setEditActive(false);
    onChange && onChange(true);
    handleClose();
  };

  const handleCloneOnClose = () => {
    setCloneActive(false);
    onChange && onChange(true);
    handleClose();
  };

  const handleBulkAction = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };

  return (
    <>
      <MDBadge badgeContent={bulkEditSelectedCount} color="light" size="xs">
        <MDButton onClick={handleBulkAction} color="dark">
          Bulk Actions&nbsp;
          <Icon>{buttonOpen ? 'expand_less' : 'expand_more'}</Icon>
        </MDButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={buttonOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {bulkEditActive && (
            <MenuItem onClick={handleEdit}>
              Modify {bulkEditSelectedCount} Selected Questions
            </MenuItem>
          )}
          {bulkEditActive && (
            <MenuItem onClick={() => setDeleteActive(true)}>
              Delete {bulkEditSelectedCount} Selected Questions
            </MenuItem>
          )}
          {bulkEditActive && <Divider />}
          <MenuItem onClick={handleSelectAll}>
            Select All Visible Results
          </MenuItem>
          {bulkEditActive && (
            <MenuItem onClick={handleClearAll}>
              Clear All Selected Questions
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={() => setCloneActive(true)}>Clone Series</MenuItem>
        </Menu>
      </MDBadge>

      <SearchEdit open={editActive} onClose={handleEditOnClose} />
      <SearchClone open={cloneActive} onClose={handleCloneOnClose} />

      <ActionDialog
        onAction={async () => {
          await globalLoadingSet(true, 'Deleting');
          setDeleteActive(false);
          handleClose();
          deleteQuestions(bulkEditSelectedQuestions)
            .then(bulkEditClearSelectedQuestions)
            .then(onChange)
            .catch((e) => log.error(e))
            .then(() => globalLoadingSet(false));
        }}
        actionLabel="Delete"
        onClose={() => setDeleteActive(false)}
        open={deleteActive}
        message={`Are you sure you want to delete ${bulkEditSelectedCount} questions?`}
        severity="error"
      />
    </>
  );
};

export default SearchBulkActions;
