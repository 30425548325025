import Card from '@mui/material/Card';
import { FC, useState } from 'react';
import { isEmail } from 'validator';

import { useRealmUser } from '../../providers/RealmUserProvider';
import useNotifications from '../../stores/notification';
import { useAutocompleteT } from '../../translate';
import MDBox from '../MDB/MDBox';
import MDButton from '../MDB/MDButton';
import MDInput from '../MDB/MDInput';

interface Props {
  onCancel: () => void;
}

const PasswordResetForm: FC<Props> = ({ onCancel }) => {
  const [email, setEmail] = useState('');

  const { emailPasswordResetRequest } = useRealmUser();
  const { addNotification } = useNotifications();
  const { T } = useAutocompleteT();

  const handleFormInputChange = ({ target }) => {
    const { value } = target;
    setEmail(value);
  };

  const onSubmit = async (email: string) => {
    try {
      await emailPasswordResetRequest({ email });
      addNotification({
        severity: 'success',
        message: T('login.resetRequest.successMessage'),
      });
      onCancel();
    } catch (error) {
      addNotification({
        message: T('login.resetRequest.errorMessage'),
        severity: 'error',
      });
    }
  };

  return (
    <Card>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              value={email}
              error={email.length > 0 && !isEmail(email)}
              helperText={
                email.length > 0 && !isEmail(email) ? T('validation.email') : ''
              }
              fullWidth
              onChange={handleFormInputChange}
            />
          </MDBox>
          <MDBox mt={2}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => onSubmit(email)}
            >
              {T('actions.submit')}
            </MDButton>
          </MDBox>
          <MDBox mt={1} mb={-1}>
            <MDButton
              variant="text"
              color="secondary"
              fullWidth
              onClick={onCancel}
            >
              {T('actions.cancel')}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default PasswordResetForm;
