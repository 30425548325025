import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useRealmApp } from './RealmAppProvider';

// Create an ApolloClient that connects to the provided Realm.App's GraphQL API
const createRealmApolloClient = (app) => {
  const link = new HttpLink({
    // Realm apps use a standard GraphQL endpoint, identified by their App ID
    uri: `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,
    // A custom fetch handler adds the logged-in user's access token to GraphQL requests
    fetch: async (uri, options) => {
      if (!app.currentUser) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      // Refreshing a user's custom data also refreshes their access token
      await app.currentUser.refreshCustomData();
      // The handler adds a bearer token Authorization header to the otherwise unchanged request
      if (options && options.headers) {
        options.headers[
          'Authorization'
        ] = `Bearer ${app.currentUser.accessToken}`;
      }
      return fetch(uri, options);
    },
  });

  const cache = new InMemoryCache({
    addTypename: false,
  });

  return new ApolloClient({ link, cache });
};

const RealmApolloProvider = ({ children }) => {
  const app = useRealmApp();
  const [client, setClient] = useState(createRealmApolloClient(app));

  useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default RealmApolloProvider;
