import { Alert, AlertColor, Icon, Slide, SnackbarOrigin } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { TransitionProps } from '@mui/material/transitions';
import React, { FC, useState } from 'react';

import useNotifications from '../stores/notification';
import MDButton from './MDB/MDButton';

const TransitionRight: FC<TransitionProps> = (props) => {
  // @ts-ignore
  return <Slide {...props} direction="left" />;
};

interface NotificationProps {
  message: string;
  severity?: AlertColor;
}
const Notification: FC<NotificationProps> = ({ severity, message }) => {
  const [open, setOpen] = useState(true);
  const [snackbarLocation] = useState<SnackbarOrigin>({
    vertical: 'top',
    horizontal: 'right',
  });

  const handleClose = () => {
    setOpen(false);
  };

  return severity ? (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={TransitionRight}
      anchorOrigin={snackbarLocation}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={severity}
        sx={{
          width: '100%',
          fontSize: '1 rem',
          lineHeight: '1.3 !important',
          color: 'white !important',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      action={
        <MDButton iconOnly variant="text" onClick={handleClose}>
          <Icon fontSize="inherit">close</Icon>
        </MDButton>
      }
      onClose={handleClose}
      TransitionComponent={TransitionRight}
      anchorOrigin={snackbarLocation}
      message={message}
    />
  );
};

const Notifications: FC = () => {
  const { notifications } = useNotifications();

  return (
    <>
      {notifications.map(({ message, severity, id }) => (
        <Notification message={message} severity={severity} key={id} />
      ))}
    </>
  );
};

export default Notifications;
