import { Card, Grid } from '@mui/material';
import { uniq } from 'lodash';
import React, { FC, useState } from 'react';

import { useQuestion } from 'graphql/hooks/useQuestion';

import AutocompleteField from 'components/Forms/Autocomplete';
import MDBox from 'components/MDB/MDBox';
import MDInput from 'components/MDB/MDInput';
import ChangeTypeDialog from 'components/Question/ChangeTypeDialog';

import { defaultAutocompletes } from 'constants/defaults';

import { useQuestionState } from 'stores';

import { labelDisplay, labelValue } from 'helpers';

const simplifyFacetOptions = (objectArray) =>
  uniq(objectArray.map(({ _id }) => labelDisplay(_id)));

const Metadata: FC = () => {
  const [typeDialogOpen, setTypeDialogOpen] = useState<string>('');

  const { getFacets } = useQuestion();
  const facets = getFacets();

  const {
    question,
    questionOptions,
    questionUpdateField,
    questionHasType,
    questionErrors,
    questionWarnings,
    questionTypes,
  } = useQuestionState();

  return (
    <Card
      sx={{
        position: 'sticky',
        top: '100px',
        borderLeft: 2,
        borderColor: `${questionOptions.colorName}.main` ?? '',
      }}
    >
      <MDBox p={2}>
        {question.questionId !== 0 && (
          <Grid item xs={12} mb={2}>
            <MDInput
              fullWidth
              label="Question ID"
              value={question.questionId}
              disabled
              variant="standard"
            />
          </Grid>
        )}
        <Grid item xs={12} mb={2}>
          <AutocompleteField
            label="Type"
            error={!!questionErrors.type}
            helpText={
              (typeof questionErrors.type === 'string' &&
                questionErrors.type) ||
              ''
            }
            value={question.type}
            getOptionLabel={(option) =>
              questionTypes
                ? questionTypes[option]
                  ? questionTypes[option].label
                  : option
                : option
            }
            onSelect={(value) => {
              if (questionTypes && questionTypes[value].isTrueFalse) {
                setTypeDialogOpen(value);
              } else {
                questionUpdateField({ field: 'type', value });
              }
            }}
            options={Object.values(questionTypes || {}).map(({ _id }) => _id)}
            disableClearable
          />
          <ChangeTypeDialog
            open={!!typeDialogOpen}
            onClose={(change) => {
              if (change) {
                questionUpdateField({
                  field: 'answers',
                  value: [
                    { feedback: '', text: 'True' },
                    { feedback: '', text: 'False' },
                  ],
                });
                questionUpdateField({ field: 'type', value: typeDialogOpen });
                setTypeDialogOpen('');
              } else {
                setTypeDialogOpen('');
              }
            }}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <AutocompleteField
            disableClearable
            label="Rank"
            value={question.rank}
            onSelect={(selected) =>
              questionUpdateField({
                field: 'rank',
                value: labelValue(selected),
              })
            }
            options={defaultAutocompletes.ranks}
            disabled={!questionHasType}
            error={questionHasType && !!questionErrors?.rank}
            helpText={questionHasType ? questionErrors.rank : ''}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <AutocompleteField
            label="Series"
            multiple
            value={question.series}
            onSelect={(selected) =>
              questionUpdateField({
                field: 'series',
                value: selected.map((item) => labelValue(item)),
              })
            }
            options={simplifyFacetOptions(facets.series)}
            freeSolo
            disabled={!questionHasType}
            error={questionHasType && !!questionErrors?.series}
            helpText={questionHasType ? questionErrors?.series : ''}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <AutocompleteField
            label="Procedures"
            multiple
            value={question?.procedures}
            onSelect={(selected) =>
              questionUpdateField({
                field: 'procedures',
                value: selected.map((item) => labelValue(item)),
              })
            }
            options={simplifyFacetOptions(facets.procedures)}
            freeSolo
            disabled={!questionHasType}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <AutocompleteField
            label="Tags"
            multiple
            value={question?.tags}
            onSelect={(selected) =>
              questionUpdateField({
                field: 'tags',
                value: selected.map((item) => labelValue(item)),
              })
            }
            options={simplifyFacetOptions(facets.tags).map((item) =>
              (item as string).toUpperCase(),
            )}
            freeSolo
            disabled={!questionHasType}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <AutocompleteField
            label="RO"
            value={question.ro}
            onSelect={(selected) =>
              questionUpdateField({
                field: 'ro',
                value: labelValue(selected),
              })
            }
            options={simplifyFacetOptions(facets.ro)}
            freeSolo
            disabled={!questionHasType}
            error={questionHasType && !!questionErrors?.ro}
            warning={questionHasType && !!questionWarnings?.ro}
            helpText={
              questionHasType
                ? questionErrors?.ro
                  ? questionErrors?.ro
                  : questionWarnings?.ro
                : ''
            }
          />
        </Grid>
      </MDBox>
    </Card>
  );
};

export default Metadata;
