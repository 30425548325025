import { cloneDeep } from 'lodash';

import {
  useGetQuestionFacetsAndCountsLazyQuery,
  useGetQuestionFacetsAndCountsQuery,
  useGetQuestionFacetsFilteredQuery,
  useGetQuestionTypesLazyQuery,
} from '../apollo';

export const useFacetsAndTypes = () => {
  const [performGetQuestionTypes] = useGetQuestionTypesLazyQuery();
  const [performGetQuestionFacetsAndCounts] =
    useGetQuestionFacetsAndCountsLazyQuery();

  const getQuestionTypes = () =>
    performGetQuestionTypes().then(({ data }) => data?.coreQuestionTypes || []);

  const getAllFacets = () =>
    performGetQuestionFacetsAndCounts()
      .then(({ data }) => data?.questionFacets)
      .then((questionFacets) => cloneDeep(questionFacets || {}));

  return {
    getQuestionTypes,
    getAllFacets,
    facetsFilteredSubscription: useGetQuestionFacetsFilteredQuery,
    facetsSubscription: useGetQuestionFacetsAndCountsQuery,
  };
};
export default useFacetsAndTypes;
