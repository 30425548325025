import { createContext, useContext, useState } from 'react';
import { Credentials } from 'realm-web';

import { useRealmApp } from './RealmAppProvider';

// Creating a user context to manage and access all the user related functions
// across different component and pages.
const RealmUserContext = createContext<any>(null);

export const useRealmUser = () => {
  const user = useContext(RealmUserContext);
  if (!user) {
    throw new Error(
      `You must call useRealmUser() inside of a <RealmUserProvider />`,
    );
  }
  return user;
};

export const RealmUserProvider = ({ children }) => {
  const [user, setUser] = useState<any>(null);

  const app = useRealmApp();

  // Function to log in user into our Realm using their email & password
  const emailPasswordLogin = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    const authedUser = await app.logIn(credentials);
    setUser(authedUser);
    return authedUser;
  };

  // Function to signup user into our Realm using their email & password
  const emailPasswordResetRequest = async (email: string) =>
    app.emailPasswordAuth.sendResetPasswordEmail(email);

  const emailPasswordReset = async ({
    token,
    tokenId,
    password,
  }: {
    token: string;
    tokenId: string;
    password: string;
  }) =>
    app.emailPasswordAuth.resetPassword({
      password,
      token,
      tokenId,
    });

  // Function to fetch-user(if the user is already logged in) from local storage
  const fetchUser = async () => {
    if (!app.currentUser) return false;
    await app.currentUser.refreshCustomData();
    // Now if we have a user we are setting it to our user context
    // so that we can use it in our app across different components.
    setUser(app.currentUser);
    return app.currentUser;
  };

  // Function to log out user from our Realm
  const logOutUser = async () => {
    if (!app.currentUser) return false;
    await app.currentUser.logOut();
    // Setting the user to null once loggedOut.
    setUser(null);
    return true;
  };

  return (
    <RealmUserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        emailPasswordLogin,
        emailPasswordReset,
        emailPasswordResetRequest,
        logOutUser,
      }}
    >
      {children}
    </RealmUserContext.Provider>
  );
};
