import { TablePagination } from '@mui/material';
import React, { FC } from 'react';

import { useSearchState } from 'stores';

const SearchPagination: FC<{ onChange?: () => any }> = ({ onChange }) => {
  const {
    searchItemsPerPage,
    searchCurrentPage,
    searchHitCount,
    searchCurrentPageSet,
    searchItemsPerPageSet,
  } = useSearchState();

  const changePage = (newPage: number) => {
    searchCurrentPageSet(newPage);
    onChange && onChange();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const changeHitsPerPage = ({ value }) => {
    searchItemsPerPageSet(parseInt(value, 10));
    onChange && onChange();
  };

  return (
    <TablePagination
      component="div"
      count={searchHitCount}
      page={searchCurrentPage}
      onPageChange={(event, newPage) => changePage(newPage)}
      rowsPerPage={searchItemsPerPage}
      rowsPerPageOptions={[10, 50, 100, 500, 1000]}
      onRowsPerPageChange={({ target }) => changeHitsPerPage(target)}
    />
  );
};

export default SearchPagination;
