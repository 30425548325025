import { Autocomplete, FormHelperText } from '@mui/material';
import { Parser } from 'html-to-react';
import React, { FC } from 'react';

import { FormField } from 'components/Forms/FormField';

import { labelDisplay } from 'helpers';

import colors from 'assets/theme/base/colors';

interface AutocompleteProps<T> {
  autoHighlight?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  filterOptions?: any;
  filterSelectedOptions?: boolean;
  freeSolo?: boolean;
  getOptionLabel?: any;
  handleNoOptionsClick?: (value: string) => void;
  helpText?: string;
  isOptionEqualToValue?: any;
  label: string;
  multiple?: boolean;
  notListedNode?: React.ReactNode;
  onSelect: (selected: T) => void;
  optionField?: keyof T;
  options: T[];
  required?: boolean;
  value?: T;
}
const AutocompleteField: FC<AutocompleteProps<any>> = (props) => {
  const {
    autoHighlight = false,
    disableClearable = false,
    disabled = false,
    error = false,
    warning = false,
    filterSelectedOptions = false,
    freeSolo = false,
    getOptionLabel = (option) =>
      typeof option === 'string' ? labelDisplay(option) : option?.label,
    helpText = '',
    isOptionEqualToValue = (option, value) => option === value,
    label,
    multiple = false,
    onSelect,
    options,
    required = false,
    value,
  } = props;

  return (
    <Autocomplete
      isOptionEqualToValue={isOptionEqualToValue}
      fullWidth
      value={value ? value : multiple ? [] : ''}
      freeSolo={freeSolo}
      multiple={multiple}
      options={options}
      disabled={disabled}
      autoHighlight={autoHighlight}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions={filterSelectedOptions}
      disableClearable={disableClearable}
      onChange={(_: any, value: any) => {
        value ? onSelect(value) : onSelect('');
      }}
      renderInput={(params) => (
        <>
          <FormField
            {...params}
            label={label}
            required={required}
            error={error}
          />
          {error && (
            <FormHelperText error={error}>
              {Parser().parse(helpText)}
            </FormHelperText>
          )}
          {warning && (
            <FormHelperText sx={{ color: colors.warning.main }}>
              {Parser().parse(helpText)}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default AutocompleteField;
