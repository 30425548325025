import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRealmUser } from 'providers/RealmUserProvider';

import BasicLayout from 'layouts/BasicLayout';

import bgImage from 'assets/images/bg-sign-in-basic.jpeg';

import LoginForm from '../components/Login/LoginForm';
import PasswordResetForm from '../components/Login/PasswordResetForm';

const LoginPage: FC = () => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, fetchUser } = useRealmUser();

  const performRedirect = () => {
    const redirectTo = location.search.replace('?redirectTo=', '');
    navigate(redirectTo ? redirectTo : '/');
  };

  useEffect(() => {
    (async () => {
      if (!user) {
        const fetchedUser = await fetchUser();
        if (fetchedUser) {
          performRedirect(); // Redirecting them once fetched.
        }
      }
    })();
  }, []);

  const onShowResetPassword = async () => setShowPasswordReset(true);

  return (
    <BasicLayout image={bgImage}>
      {showPasswordReset ? (
        <PasswordResetForm onCancel={() => setShowPasswordReset(false)} />
      ) : (
        <LoginForm onResetRequest={onShowResetPassword} />
      )}
    </BasicLayout>
  );
};

export default LoginPage;
