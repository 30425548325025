import { Checkbox, Divider, FormControlLabel, FormGroup } from '@mui/material';
import { includes } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { FacetType } from 'graphql/apollo';

import MDBadge from 'components/MDB/MDBadge';
import MDBox from 'components/MDB/MDBox';
import MDButton from 'components/MDB/MDButton';
import MDTypography from 'components/MDB/MDTypography';

import { useQuestionState, useSearchState } from 'stores';

import { labelDisplay } from 'helpers';

interface SearchRefinementProps {
  facet: string;
  label?: string;
  onChange?: () => any;
}

const SearchRefinementItem: FC<{
  item: FacetType;
  facet: string;
  onChange?: () => any;
}> = ({ item, facet, onChange }) => {
  const { _id, count } = item;

  const [colorName, setColorName] = useState('secondary');
  const [label, setLabel] = useState('');

  const { questionTypes } = useQuestionState();
  const {
    searchFacetsActive,
    searchFacetsActiveSet,
    searchFacetsActiveRemove,
  } = useSearchState();

  useEffect(() => {
    if (questionTypes && questionTypes[item._id as string]) {
      const { label, colorName } = questionTypes[item._id as string];
      setLabel(label);
      setColorName(colorName);
    } else {
      setLabel(labelDisplay(_id));
    }
  }, [_id]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={includes(searchFacetsActive[facet], item._id)}
          onChange={({ target }) => {
            target.checked
              ? searchFacetsActiveSet(facet, item._id || '')
              : searchFacetsActiveRemove(facet, item._id || '');
            onChange && onChange();
          }}
        />
      }
      label={
        <MDBox flex="flex" flexDirection="row">
          <MDTypography
            variant="button"
            component="div"
            sx={{
              display: 'inline-block',
              maxWidth: '125px',
              lineHeight: 'inherit',
            }}
            noWrap
          >
            {label}
          </MDTypography>
          <MDBadge
            badgeContent={count}
            max={9999}
            sx={{ marginTop: '-15px' }}
            size="sm"
            circular
            color={colorName}
          />
        </MDBox>
      }
    />
  );
};

const SearchRefinement: FC<SearchRefinementProps> = ({
  facet,
  label,
  onChange,
}) => {
  const { searchFacetsAvailable } = useSearchState();
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {searchFacetsAvailable[facet] &&
        searchFacetsAvailable[facet].length > 0 && (
          <>
            <MDTypography variant="h6">{label ? label : facet}</MDTypography>
            <FormGroup>
              {searchFacetsAvailable[facet]
                .filter((item, index) => (!showMore ? index <= 9 : true))
                .map((item) => (
                  <SearchRefinementItem
                    item={item}
                    key={item._id}
                    facet={facet}
                    onChange={onChange}
                  />
                ))}
            </FormGroup>
            {searchFacetsAvailable[facet].length > 9 && (
              <MDButton onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Show less' : 'Show more'}
              </MDButton>
            )}
            <Divider />
          </>
        )}
    </>
  );
};

export default SearchRefinement;
