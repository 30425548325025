import { FC } from 'react';

import MDInput from '../MDB/MDInput';

interface Props {
  label?: string;
  [key: string]: any;
}

export const FormField: FC<Props> = ({ label, ...rest }) => {
  return (
    <MDInput
      variant="standard"
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
};
