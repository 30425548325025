import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';

import MDButton from 'components/MDB/MDButton';

interface Props {
  open: boolean;
  onClose: (change?: boolean) => void;
}

const ChangeTypeDialog: FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to change to this type?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By changing the current question to a type that has true and false
          answers, the current answers will be replace with True and False.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={() => onClose()} autoFocus>
          Cancel
        </MDButton>
        <MDButton onClick={() => onClose(true)} color="error">
          Change Type
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeTypeDialog;
