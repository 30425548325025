import { cloneDeep } from 'lodash';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AdvancedLayout from 'layouts/AdvancedLayout';

import { useQuestion } from 'graphql/hooks/useQuestion';

import QuestionForm from 'components/Question/Question';

import { useQuestionState } from 'stores';

const Create: FC = () => {
  const navigate = useNavigate();
  const { addQuestion } = useQuestion();
  const { question, questionReset } = useQuestionState();

  useEffect(() => {
    questionReset();
  }, []);

  return (
    <AdvancedLayout>
      <QuestionForm
        onSave={() => {
          const { _id, ...rest } = cloneDeep(question);
          addQuestion({ ...rest, modified: new Date().toISOString() }).then(
            (id) => navigate(`/questions/edit/${id}`),
          );
        }}
      />
    </AdvancedLayout>
  );
};

export default Create;
