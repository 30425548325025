import { Backdrop, CircularProgress } from '@mui/material';
import React, { FC } from 'react';

import MDBox from 'components/MDB/MDBox';
import MDTypography from 'components/MDB/MDTypography';

import { useGlobalState } from 'stores';

const Loading: FC = () => {
  const { globalMessage, globalLoading } = useGlobalState();
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={globalLoading}
    >
      <MDBox color="light" align="center">
        <CircularProgress color="inherit" />
        {globalMessage && (
          <MDTypography
            variant="caption"
            color="inherit"
            align="center"
            component="div"
          >
            {globalMessage}
          </MDTypography>
        )}
      </MDBox>
    </Backdrop>
  );
};

export default Loading;
