import { hookstate, useHookstate } from '@hookstate/core';

export type SearchOptions = 'phrase' | 'text' | 'wildcard';

interface SearchState {
  activeFacets: {
    types: string[];
    ranks: string[];
    series: string[];
    procedures: string[];
    tags: string[];
    ro: string[];
  };
  availableFacets: {
    types: string[];
    ranks: string[];
    series: string[];
    procedures: string[];
    tags: string[];
    ro: string[];
  };
  highlight: boolean;
  resultsOptions: {
    currentPage: number;
    hitsPerPage: number;
  };
  totalHits: number;
  text: string;
  options: {
    wildcard: boolean;
    text: boolean;
    phrase: boolean;
  };
}

const defaultSearchState: SearchState = {
  activeFacets: {
    types: [],
    ranks: [],
    series: [],
    procedures: [],
    tags: [],
    ro: [],
  },
  availableFacets: {
    types: [],
    ranks: [],
    series: [],
    procedures: [],
    tags: [],
    ro: [],
  },
  highlight: true,
  resultsOptions: {
    currentPage: 0,
    hitsPerPage: 50,
  },
  totalHits: 0,
  text: '',
  options: {
    wildcard: false,
    text: false,
    phrase: true,
  },
};

const searchState = hookstate<SearchState>(defaultSearchState);

const useSearchState = () => {
  const state = useHookstate(searchState);

  return {
    get searchCurrentPage() {
      return state.resultsOptions.currentPage.get();
    },
    get searchFacetsActive() {
      return state.activeFacets.get();
    },
    get searchFacetsAvailable() {
      return state.availableFacets.get();
    },
    get searchHighlight() {
      return state.highlight.get();
    },
    get searchHitCount() {
      return state.totalHits.get();
    },
    get searchItemsPerPage() {
      return state.resultsOptions.hitsPerPage.get();
    },
    get searchOptions() {
      return state.options.get();
    },
    get searchText() {
      return state.text.get();
    },
    searchCurrentPageSet(page: number) {
      return state.resultsOptions.currentPage.set(page);
    },
    searchFacetsActiveSet(facet: string, item: string) {
      return state.activeFacets.set((state) => ({
        ...state,
        [facet]: [...state[facet], item],
      }));
    },
    searchFacetsActiveRemove(facet: string, item: string) {
      return state.activeFacets.set((state) => ({
        ...state,
        [facet]: state[facet].filter((i) => i !== item),
      }));
    },
    searchFacetsActiveRemoveAll() {
      return state.activeFacets.set({ ...defaultSearchState.activeFacets });
    },
    searchFacetsAvailableSet(facet, items: any[]) {
      state.availableFacets[facet].set([]);
      for (const item of items) {
        state.availableFacets[facet].merge([
          { _id: item._id, count: item.count },
        ]);
      }
    },
    searchHighlightToggle() {
      return state.highlight.set(!state.highlight.get());
    },
    searchHitCountSet(count: number) {
      return state.totalHits.set(() => count);
    },
    searchItemsPerPageSet(count: number) {
      return state.resultsOptions.hitsPerPage.set(count);
    },
    searchOptionsSet(name: SearchOptions, value: boolean) {
      return state.options.nested(name).set(value);
    },
    searchQuery() {
      return {
        text: state.text.get(),
        type: state.activeFacets.types.get()[0],
        rank: state.activeFacets.ranks.get()[0],
        series: state.activeFacets.series.get().map((item) => item),
        procedures: state.activeFacets.procedures.get().map((item) => item),
        tags: state.activeFacets.tags.get().map((item) => item),
        ro: state.activeFacets.ro.get()[0],
        options: state.options.get(),
        skipPage: state.resultsOptions.currentPage.get(),
        perPage: state.resultsOptions.hitsPerPage.get(),
      };
    },
    searchTextSet(text: string) {
      return state.text.set(text);
    },
  };
};

export default useSearchState;
