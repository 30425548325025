import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import log from 'loglevel';
import React, { FC, useEffect, useState } from 'react';

import Answer from 'components/Question/Answer';

import { useQuestionState } from 'stores';

const Answers: FC = () => {
  const [answerIds, setAnswerIds] = useState([1, 2, 3, 4]);
  const { questionReorderAnswer, questionAnswers, questionErrors } =
    useQuestionState();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 10,
      },
    }),
  );

  useEffect(() => {
    setAnswerIds(
      Object.keys(questionAnswers).map((index) => parseInt(index, 10) + 1),
    );
  }, [questionAnswers.length]);

  const handleDragEnd = ({ over, active: { id } }: DragEndEvent) => {
    if (over && over.id) {
      const activeIndex = parseInt(id.toString(), 10) - 1;
      const destinationIndex = parseInt(over.id.toString(), 10) - 1;
      questionReorderAnswer(activeIndex, destinationIndex);
    }
    log.debug(`Answer ${id} was was place at ${over?.id}`);
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={answerIds}>
        {questionAnswers.map(
          ({ text = '', feedback = '', correct = false }, index) => (
            <Answer
              index={index}
              key={index}
              text={text}
              feedback={feedback}
              correct={correct}
              errors={
                questionErrors?.answers
                  ? questionErrors?.answers[index]
                  : undefined
              }
            />
          ),
        )}
      </SortableContext>
    </DndContext>
  );
};

export default Answers;
