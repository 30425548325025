import { Alert, CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import AdvancedLayout from 'layouts/AdvancedLayout';

import useTrash from 'graphql/hooks/useTrash';

import MDBox from 'components/MDB/MDBox';
import TrashItem from 'components/Trash/TrashItem';

const Trash: FC = () => {
  const [loading, setLoading] = useState(false);
  const [trash, setTrash] = useState<any[]>([]);

  const { getTrashes } = useTrash();

  useEffect(() => {
    setLoading(true);
    getTrashes()
      .then((trashes) => {
        if (trashes) {
          setTrash(trashes);
        }
      })
      .catch()
      .then(() => setLoading(false));
  }, []);

  return (
    <AdvancedLayout>
      <MDBox mb={2}>
        <Alert severity="warning" elevation={1}>
          Deleted questions are purged after 30 Days.
        </Alert>
      </MDBox>
      {loading ? (
        <CircularProgress />
      ) : (
        trash.map(({ _id, text, type, deleted }) => (
          <TrashItem
            id={_id}
            text={text}
            type={type}
            date={deleted}
            key={_id}
          />
        ))
      )}
      {!loading && trash.length === 0 && <p>Hooray, no trash!</p>}
    </AdvancedLayout>
  );
};

export default Trash;
